<mat-nav-list class="sidebar">
  <!-- display: block -->
  <a
    class="sidebar__item"
    mat-list-item
    (click)="toggleNotification(false)"
    [ngStyle]="show ? { 'background-color': '#ec0000' } : { 'background-color': '#990000' }"
  >
    {{ 'DASHBOARD.NOTIFICATIONS_LABEL' | translate }}

    <div class="flex-spacer d-flex justify-content-end">
      <span
        *ngIf="!show && notifications?.length > 0 && !isOpenedNotifications"
        class="sidebar__badge"
        >{{ notifications?.length }}</span
      >
      <span *ngIf="show"><img src="../../../../assets/sys-017-chevron-left-white.svg" /></span>
    </div>
  </a>
  <a class="sidebar__item" mat-list-item (click)="openTermsModal()">
    {{ 'DASHBOARD.TERMS_LABEL' | translate }}</a
  >
  <!-- display: block -->
  <a
    class="sidebar__item"
    mat-list-item
    routerLink="/"
    queryParamsHandling="merge"
    [queryParams]="{ token: undefined }"
  >
    {{ 'DASHBOARD.LANG_LABEL' | translate }}
    <!-- <div class="flex-spacer d-flex justify-content-end">
      <mat-button-toggle-group
        class="toggle-group"
        [(ngModel)]="lang"
        (change)="onLangPressed($event.value)"
      >
        <mat-button-toggle [ngStyle]="{'background-color': lang === 'es_ES' ? '#e0e0e0' : 'white' }" value="es_ES">ES</mat-button-toggle>
        <mat-button-toggle [ngStyle]="{'background-color': lang === 'en_US' ? '#e0e0e0' : 'white' }" value="en_US">EN</mat-button-toggle>
      </mat-button-toggle-group>

    </div> -->
    <div class="flex-spacer d-flex justify-content-end">
      <button
        #buttones
        class="btn-lang btn-es"
        [ngStyle]="{ 'background-color': lang === 'es_ES' ? '#e0e0e0' : 'white' }"
        (click)="onLangPressed('es_ES')"
      >
        ES
      </button>
      <button
        #buttonen
        class="btn-lang btn-en"
        [ngStyle]="{ 'background-color': lang === 'en_US' ? '#e0e0e0' : 'white' }"
        (click)="onLangPressed('en_US')"
      >
        EN
      </button>

      <button
        #buttonpt
        class="btn-lang btn-pt"
        [ngStyle]="{ 'background-color': lang === 'pt_PRT' ? '#e0e0e0' : 'white' }"
        (click)="onLangPressed('pt_PRT')"
      >
        PT
      </button>
    </div>
  </a>
  <a
    *ngFor="let report of staticReport"
    class="sidebar__item"
    mat-list-item
    (click)="onReportClick(report)"
    >{{ report.nbInforme }}</a
  >
  <div *ngIf="isAdmin">
    <a
      class="sidebar__item"
      mat-list-item
      (click)="navigateUserManagement()"
      [routerLink]="['/user-management']"
      [queryParams]="{ token: undefined }"
      >{{ 'DASHBOARD.USER_MANAGEMENT' | translate }}</a
    >
  </div>

  <a class="sidebar__item" mat-list-item (click)="logOut()">{{
    'DASHBOARD.LOGOUT_LABEL' | translate
  }}</a>
</mat-nav-list>

<div class="notification-list" *ngIf="show">
  <div class="notification-container">
    <span class="card-title-text">{{ 'DASHBOARD.NOTIFICATIONS_LABEL' | translate }}</span>
    <div class="notification-close pt-3 mt-2">
      <button mat-icon-button (click)="toggleNotification(true)">
        <mat-icon class="red-icon default-icon">close</mat-icon>
        <span class="cdk-visually-hidden"> close sidenav </span>
      </button>
    </div>
  </div>

  <div class="notification" *ngFor="let notification of notifications">
    <mat-card class="container">
      <div class="details d-flex justify-content-end">
        <img src="../../../../assets/doc-022-calendar (1).svg" />
        <div class="text-details">
          {{ utils.dateToShortString(notification.feDistribucion) }}
        </div>
      </div>
      <a (click)="onNotificationClick(notification)">
        <span class="text-container" [class.bold-link]="notification.swResaltar === 'S'">{{
          notification?.nbTrabajo
        }}</span>
      </a>
    </mat-card>
  </div>
</div>
