<div *ngIf="loadingBarService.value$ | async" class="home__spinner d-flex">
  <app-spinner></app-spinner>
</div>
<div class="user-mang__title">
  <h2 [innerHTML]="'AUDIFY.USERMANAGEMENT.title' | translate"></h2>
  <button mat-basic-button class="link-button" (click)="generateActivationKey()">
    <mat-icon color="primary" class="icon-color">lightbulb_outline</mat-icon>
    <span [innerHTML]="'AUDIFY.USERMANAGEMENT.key' | translate"></span>
  </button>
</div>
<!-- *ngIf="modelService.accesoTotal" -->
<div class="user-mang__body">
  <form class="user-mang__search" [formGroup]='usersFilterForm' (ngSubmit)="find()">
    <div class="containerForm">
      <div class="busqueda-nombre">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'AUDIFY.FILTERS.name' | translate }}"
            [(ngModel)]="search.nombre"
            name="name"
            autocomplete="off"
            formControlName="name"
          />
        </mat-form-field>
      </div>
      <div class="busqueda-tipo-acceso">
        <mat-form-field>
          <mat-select
            placeholder="{{ 'AUDIFY.FILTERS.accessType' | translate }}"
            name="accessType"
            [(ngModel)]="search.tipoAcceso"
            multiple
         
            disableOptionCentering
            panelClass="selectAccessType"
            formControlName="accessType"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="accessTypeMultiFilterCtrl"
                noEntriesFoundLabel="{{ 'AUDIFY.FILTERS.COMBOS.noResults' | translate }}"
                placeholderLabel="{{ 'AUDIFY.FILTERS.COMBOS.accessTypePlaceholder' | translate }}"
              >
              </ngx-mat-select-search> 
            </mat-option>
            <mat-option *ngFor="let type of types" [value]="type.accessId">
              {{ type.accessName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="busqueda-permisos">
        <mat-form-field>
          <mat-select
            placeholder="{{ 'AUDIFY.FILTERS.permissions' | translate }}"
            name="permissions"
            [(ngModel)]="search.permisos"
            multiple
        
            disableOptionCentering
            panelClass="selectPermissions"
            formControlName="permissions"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="permissionsMultiFilterCtrl"
                noEntriesFoundLabel="{{ 'AUDIFY.FILTERS.COMBOS.noResults' | translate }}"
                placeholderLabel="{{ 'AUDIFY.FILTERS.COMBOS.permissionPlaceholder' | translate }}"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let permission of permissions" [value]="permission.permisoId">
              {{ permission.permisoName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="busqueda-geography">
        <mat-form-field>
          <mat-select
            placeholder="{{ 'AUDIFY.FILTERS.geography' | translate }}"
            name="geography"
            [(ngModel)]="search.geografia"
            multiple
           
            disableOptionCentering
            panelClass="selectGeographies"
            formControlName="geography"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="geographyMultiFilterCtrl"
                noEntriesFoundLabel="{{ 'AUDIFY.FILTERS.COMBOS.noResults' | translate }}"
                placeholderLabel="{{ 'AUDIFY.FILTERS.COMBOS.geographyPlaceholder' | translate }}"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let geography of geographies"
              (onSelectionChange)="cambioGeografia($event)"
              [value]="geography.idGeografia"
            >
              {{ geography.geo }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="busqueda-division">
        <mat-form-field>
          
          <mat-select
            placeholder="{{ 'AUDIFY.FILTERS.division' | translate }}"
            name="division"
            [(ngModel)]="search.division"
            multiple
            [disabled]="search.geografia === null"
            disableOptionCentering
            formControlName="division"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="divisionsMultiFilterCtrl"
                noEntriesFoundLabel="{{ 'AUDIFY.FILTERS.COMBOS.noResults' | translate }}"
                placeholderLabel="{{ 'AUDIFY.FILTERS.COMBOS.divisionPlaceholder' | translate }}"
              >
              </ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let dep of divisions" [value]="dep.divisionId">
              {{ dep.divisionName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="actionsForm">
      <div class="actions">
        <mat-checkbox name="includeUnsubscribeUsers" color="primary" [(ngModel)]="search.usuariosBaja" formControlName="includeUnsubscribeUsers">
          {{ 'AUDIFY.FILTERS.include' | translate }}
        </mat-checkbox>
        <div class="busqueda-btns">
          <button mat-button type="button" class="bg-color-white" (click)="cleanForm()">
            {{ 'AUDIFY.FILTERS.clean' | translate }}
          </button>
          <button mat-button type="button" class="bg-color-red" (click)="searchUsers($event)" (keyup.enter)="searchUsers($event)">
            <mat-icon matSuffix style="font-size: 1.5em">search</mat-icon>
            {{ 'AUDIFY.FILTERS.search' | translate }}
          </button>
        </div>
      </div>
      <button class="download-btn" mat-button type="button"  title="generate Excel" (click)="exportExcel()" [disabled]="!results">
        <img [ngClass]="{ 'disabled-icon': !results }" src="../../../assets/download.png" alt="{{ 'AUDIFY.USERMANAGEMENT.download' | translate }}"
        />
        {{ 'AUDIFY.USERMANAGEMENT.download' | translate }}
      </button>
    </div>
  </form>

  <mat-card class="user-mang__table">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 userTable">
      <ng-container matColumnDef="userId">
        <th class="userId" mat-header-cell *matHeaderCellDef mat-sort-header >
          {{ 'AUDIFY.USERMANAGEMENT.COLDEF.userID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="codcolumnUser">{{ element.userId }}</td>
      </ng-container>

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'AUDIFY.USERMANAGEMENT.COLDEF.name' | translate }}
        </th>
        <td class="nombre" mat-cell *matCellDef="let element">{{ element.userName }}</td>
      </ng-container>

      <ng-container matColumnDef="userType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'AUDIFY.USERMANAGEMENT.COLDEF.type' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.userType }}</td>
      </ng-container>

      <ng-container matColumnDef="ambitoConsulta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'AUDIFY.USERMANAGEMENT.COLDEF.area' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.ambitoConsulta }}</td>
      </ng-container>

      <ng-container matColumnDef="ultimoAcceso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'AUDIFY.USERMANAGEMENT.COLDEF.lastAccess' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.ultimoAcceso | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="vip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'AUDIFY.USERMANAGEMENT.COLDEF.vip' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.vip }}</td>
      </ng-container>

      <ng-container matColumnDef="permisos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'AUDIFY.USERMANAGEMENT.COLDEF.permissions' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <p *ngIf="element.permisos">
            <span
              *ngFor="let item of permiOpt"
              [ngClass]="{
                'has-permission': element.permisos.includes(item),
                'no-permission': !element.permisos.includes(item)
              }"
            >
              {{ item }}
            </span>
          </p>
        
        </td>
      </ng-container>

      <ng-container matColumnDef="notificaciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'AUDIFY.USERMANAGEMENT.COLDEF.notifications' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.notificaciones }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="userColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: userColumns"
        class="rows"
        (click)="showUserDetail(row)"
      ></tr>
    </table>

    <mat-paginator
      #paginadorAuditores
      [pageSizeOptions]="[5, 10, 20]"
      (page)="cambioPaginacion($event)"
      showFirstLastButtons
    >
      1</mat-paginator
    >
  </mat-card>
</div>