import { Injectable, Inject, SecurityContext } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
} from '@angular/common/http';
import { catchError, debounceTime } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isMobile } from '../utils/files';
import { DialogService } from '../../dialog/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { STORAGE_ERRORMODALISOPEN } from '../constants/storage-keys';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../services/app/auth/auth.service';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private authService: AuthService,
    private dialogService: DialogService,
    private router: Router,
    public translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {

   }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,

  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // alert("status: " + err.status);
        console.error("status: " + err.status);
        console.error("error: " + err);
        this.check401(err);
        this.checkTimeout(err);

        return throwError(err.status);
      })
    );
  }

  check401(err: any) {
    if (err.status === 401) { // usuario ha perdido la sesión o no tiene
      this.redirectToEstructural();
    }
  }

  checkTimeout(err: any) {
    if (err.status === 0 || err.status === 408 || err.status === 504) {
      const errorTitle = (err.status === 0) ? this.translateService.instant('FAILCONNECTION.TITLE') : this.translateService.instant('TIMEOUT.TITLE');
      const errorMessage = (err.status === 0) ? this.translateService.instant('FAILCONNECTION.SUBTITLE') : this.translateService.instant('TIMEOUT.SUBTITLE');
      if (isMobile()) {
        if (localStorage.getItem(STORAGE_ERRORMODALISOPEN) !== "true") {
          localStorage.setItem(STORAGE_ERRORMODALISOPEN, "true");
          this.showConfirmDialog(errorTitle, errorMessage);
        }
      } else {
        this.snackBar.open(errorTitle, undefined, {
          duration: 5000,
        });
        this.redirect(err.status);
      }
    }
  }
  showConfirmDialog(errorTitle: string, errorMessage: string) {
    //Mostrar modal
    this.dialogService.showConfirmDialog(
      errorTitle,
      errorMessage
    ).subscribe(result => {
      //depende de la respuesta, reintentar o logout
      if (result === 1) {
        this.redirect(200);
      } else {
        this.redirect(0);
      }
    });
  }

  redirect(status: number) {
    if (status === 0 || status === 401) {
      this.redirectToEstructural();
    } else if (status === 408 || status === 504) {
      localStorage.setItem(STORAGE_ERRORMODALISOPEN, "false");
      this.router.navigate(['/404'], {
        skipLocationChange: true, // minimal effect. see https://github.com/angular/angular/issues/17004
      });
    } else {
      localStorage.setItem(STORAGE_ERRORMODALISOPEN, "false");
      this.router.navigate(['/home'], {
        skipLocationChange: true, // minimal effect. see https://github.com/angular/angular/issues/17004
      });
    }
  }

  redirectToEstructural() {
    if (isMobile()) {
      this.authService.logout();
    } else {
      this.authService.logout();
      this.authService.accessConfig(true,false);
    }
  }

}
