import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../app/api/api.service';
import { Geography } from '../../models/entities/geography';

@Injectable({
  providedIn: 'root',
})
export class GeographyService {
  private readonly baseUrl = '/api/geographies';

  constructor(private api: ApiService) {}

  getUserGeographies(): Observable<Geography[]> {
    return this.api.get<Geography[]>(`${this.baseUrl}/getUserGeographies`);
  }

  getNestedGeographies(divisionId?: number): Observable<Record<string, Geography>> {
    const url = `${this.baseUrl}/getNestedGeographies` + (divisionId ? `/${divisionId}` : '');
    return this.api.get<Record<string, Geography>>(url);
  }

  getGeographyNameByCode(code: string | number): Observable<Geography> {
    return this.api.get<Geography>(`${this.baseUrl}/getGeographyNameByCode/${code}`);
  }
}
