import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { inject } from '@angular/core/testing';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AriesVisibility, DataModalMessage, DataUsers, UserDetailDTO, UserAudify, UserAudifyDTO, SearchAudify } from 'src/app/shared/models/audify';
import { AESHelper } from '../helpers/aes';


import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { UserManagementService } from '../services/user-management.service';
import { AuthService } from '../../core/services/app/auth/auth.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  messageData: DataModalMessage;
  userDetail: UserDetailDTO;
  openModal: boolean;
  respuesta: boolean;
  showSpinner: boolean;
  message: string;
  valor: number;
  audifyUser: UserAudify;

  formComments: FormGroup = this.fb.group({
    comentarios: '',
  })
  formPermitsSliders: FormGroup = this.fb.group({
    informes: false,
    mantenimiento: false,
    recomendaciones: false,
    administracion: false,
    planificacion: false,
    vip: false,
    envioCorreo: false,
  });
  formNotificationSliders: FormGroup = this.fb.group({
    push: false,
    correo: false,
    remitente: '',
    cco: '',
  });

  formUserInternoSlider: FormGroup = this.fb.group({
    userInterno: false
  });

  codpas: string = '';

  userData: DataUsers;
  @Output() dataUser: EventEmitter<any[]> = new EventEmitter<any[]>();
  constructor(
    public dialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private translate: TranslateService,
    public dialog: MatDialog,
    private authService: AuthService,
    public loadingBarService: LoadingBarService,
    private userManagementService: UserManagementService,
    private aesHelper: AESHelper,
    private _snackBar: MatSnackBar
  ) {
    this.respuesta = false;
    this.valor = 0;
    this.fb = fb;
    this.messageData = {
      title: '',
      textBody: '',
    };
    this.showSpinner = true;
    this.openModal = false;

    this.userData = {
      userId: '',
      userName: '',
      userType: '',
      ambitoConsulta: '',
      ultimoAcceso: '',
      vip: '',
      permisos: [],
      notificaciones: ''
    };
    this.message = '';

    this.userDetail = {
      userId: '',
      userName: '',
      userType: '',
      ambitoConsulta: '',
      userDFS: '',
      email: '',
      ariesVisibilityData: [],
      comments: [],
      userAudify: '',
      audify: false,
      informes: false,
      recomendaciones: false,
      planificacion: false,
      envioCorreo: false,
      mantenimiento: false,
      administracion: false,
      vip: false,
      push: false,
      correo: false,
      remitente: '',
      cco: '',
      userInterno: false,
      comentarios: ''
    };
    this.audifyUser = {
      userAudify: '',
      audify: '',
      informes: '',
      recomendaciones: '',
      planificacion: '',
      envioCorreo: '',
      mantenimiento: '',
      administracion: '',
      vip: '',
      push: '',
      correo: '',
      remitente: '',
      cco: '',
      userInterno: '',
      comentarios: '',
    }
  }

  ngOnInit() {
    this.authService.refreshToken().subscribe();
    this.userData = this.data.userData;
    this.getUserData();
    this.initAriesVisibility();
    this.getUserAudifyData();
    this.chargeUser();
  }

  chargeUser() {
    this.userDetail.audify = this.audifyUser.audify === 'S' ? true : false;
    this.userDetail.informes = this.audifyUser.informes === 'S' ? true : false;
    this.userDetail.recomendaciones = this.audifyUser.recomendaciones === 'S' ? true : false;
    this.userDetail.planificacion = this.audifyUser.planificacion === 'S' ? true : false;
    this.userDetail.envioCorreo = this.audifyUser.envioCorreo === 'S' ? true : false;
    this.userDetail.mantenimiento = this.audifyUser.mantenimiento === 'S' ? true : false;
    this.userDetail.administracion = this.audifyUser.administracion === 'S' ? true : false;
    this.userDetail.vip = this.audifyUser.vip === 'S' ? true : false;
    this.userDetail.push = this.audifyUser.push === 'S' ? true : false;
    this.userDetail.correo = this.audifyUser.correo === 'S' ? true : false;
    this.userDetail.cco = this.audifyUser.cco;
    this.userDetail.userInterno = this.audifyUser.userInterno === 'S' ? true : false;
    this.userDetail.comentarios = this.audifyUser.comentarios;
    this.userDetail.remitente = this.audifyUser.remitente;
  }

  changeAudifyAccess(event: MatSlideToggleChange): void {
    this.userDetail.audify = event.checked;

    if (!this.userDetail.userInterno && !this.userDetail.audify) {
      this.userManagementService.desactivarUsuario(this.userData.userId).subscribe(res => {
        if (res.estado.toLowerCase() == 'KO') {
          this._snackBar.open(res.descripcionResultado);
        }
      });
      
    }
    if(!this.userDetail.audify){
      this.borrarDispositivos();
    }

  }

  changeTypeUser(event: MatSlideToggleChange): void {
    if (this.warningSizeUser()) {
      this.userDetail.userInterno = event.checked;
      if (!this.userDetail.userInterno) {
        this.messageData = {
          title: this.translate.instant('AUDIFY.MODAL.externValTitle'),
          textBody:
            this.translate.instant('AUDIFY.MODAL.externaValMessageInit') +
            '\n' +
            this.userDetail.userId +
            ' - ' +
            this.userDetail.userName +
            '\n\n' +
            this.translate.instant('AUDIFY.MODAL.externaValMessageEnd'),
          label: this.translate.instant('AUDIFY.MODAL.newTempCodeLabel'),
          buttons: [
            this.translate.instant('AUDIFY.MODAL.activate'),
            this.translate.instant('AUDIFY.MODAL.cancel'),
          ],
          input: true,
          codpas: ''
        };

        const dialogRef = this.dialog.open(ModalMessageComponent, { data: this.messageData });

        dialogRef.afterClosed().subscribe(res => {
          const encrypted = this.aesHelper.encrypt(res);
          this.userDetail.codpas = encrypted;
          this.userManagementService.convetirUsuarioExterno(this.userDetail).subscribe(res => {
            if (res.estado.toLowerCase() == 'KO') {
              this._snackBar.open(this.translate.instant(res.descripcionResultado, { value: this.userDetail.userId }));
            } else {
              this.messageData = {
                title: this.translate.instant('AUDIFY.MODAL.exterSaved'),
                textBody:
                  this.userDetail.userId +
                  '\n\n' +
                  this.translate.instant('AUDIFY.MODAL.externaSavedMessageEnd'),
              };
              const dialogRef = this.dialog.open(ModalMessageComponent, {
                data: this.messageData
              });
              dialogRef.afterOpened().subscribe(_ => {
                setTimeout(() => {
                  dialogRef.close();
                }, 6000)
              })
            }
          });
        });

      } else {
        this.userManagementService.convetirUsuarioInterno(this.userDetail.userId).subscribe(res => {
          if (res.estado.toLowerCase() == 'KO') {
            this._snackBar.open(this.translate.instant(res.descripcionResultado, { value: this.userDetail.userId }));
          }
          else {
            this.messageData = {
              title: this.translate.instant('AUDIFY.MODAL.exterSaved'),
              textBody:
                this.userDetail.userId +
                '\n\n' +
                this.translate.instant('AUDIFY.MODAL.externaSavedMessageEnd'),
            };
            const dialogRef = this.dialog.open(ModalMessageComponent, {
              data: this.messageData
            });
            dialogRef.afterOpened().subscribe(_ => {
              setTimeout(() => {
                dialogRef.close();
              }, 6000)
            })
          }
        });
      }
    } else {
      this.resolveSlider("userInterno");
    };

  }

  addComments(): void {
    this.verifyComments();
    if (this.audifyUser.audify === 'N' && this.userDetail.audify) {
      if (this.userDetail.comentarios) {
        this.userDetail.comentarios = this.userDetail.comentarios +
          "\nUsuario " + this.audifyUser.userAudify + " ACTIVADO - " + this.getDate() + "."
      } else {
        this.userDetail.comentarios = "Usuario " + this.audifyUser.userAudify + " ACTIVADO - " + this.getDate() + "."
      }

    } else if (this.audifyUser.audify === 'S' && this.userDetail.audify === false) {
      if (this.userDetail.comentarios) {
        this.userDetail.comentarios = this.userDetail.comentarios +
          "\nUsuario " + this.audifyUser.userAudify + " DESACTIVADO - " + this.getDate() + ".";
      } else {
        this.userDetail.comentarios = "Usuario " + this.audifyUser.userAudify + " DESACTIVADO - " + this.getDate() + "."
      }
    }
  }

  borrarDispositivos(): void {
    const user: UserAudifyDTO = {
      usuarioAudify: this.audifyUser.userAudify,
      audify: this.userDetail.audify ? 'S' : 'N',
      informes: this.userDetail.informes ? 'S' : 'N',
      recomendaciones: this.userDetail.recomendaciones ? 'S' : 'N',
      planificacion: this.userDetail.planificacion ? 'S' : 'N',
      envioCorreo: this.userDetail.envioCorreo ? 'S' : 'N',
      mantenimiento: this.userDetail.mantenimiento ? 'S' : 'N',
      administracion: this.userDetail.administracion ? 'S' : 'N',
      vip: this.userDetail.vip ? 'S' : 'N',
      push: this.userDetail.push ? 'S' : 'N',
      correo: this.userDetail.correo ? 'S' : 'N',
      cco: this.userDetail.cco,
      usuarioInterno: this.userDetail.userInterno ? 'S' : 'N',
      comentarios: this.userDetail.comentarios,
      remitente: this.userDetail.remitente
    }
    this.userManagementService.borrarDispositivos(user).subscribe(res => {
      this.message = 'AUDIFY.MODAL.errorBorrado';
      if (res === 200) {
        this.message =  'AUDIFY.MODAL.dispositivosBorrados';
      } else if(res === 400){
        this.message =  'AUDIFY.MODAL.errorClient';
      }
      this.messageData = {
        title: this.translate.instant('AUDIFY.MODAL.message'),
        textBody:
          this.userDetail.userId +
          '\n\n' +
          this.translate.instant(this.message),
      };

      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: this.messageData
      });
      dialogRef.afterOpened().subscribe(_ => {
        setTimeout(() => {
          dialogRef.close();
        }, 6000)
      })
    });
  }

  verifyComments(): void {
    if (this.userDetail.comentarios) {
      var myString = this.userDetail.comentarios;
      var stringLength = myString.length; // this will be 16
      var lastChar = myString.charAt(stringLength - 1);
      if (lastChar !== '.') {
        this.userDetail.comentarios = myString + (".");
      }
    }
  }
  warningSizeUser(): boolean {
    this.respuesta = true;
    this.valor = this.audifyUser.userAudify.length;
    if (this.valor > 50) {
      this.respuesta = false;
      this.messageData = {
        title: this.translate.instant('AUDIFY.MODAL.exterWarning'),
        textBody:
          this.userDetail.userId +
          '\n\n' +
          this.translate.instant('AUDIFY.MODAL.externaWarningMessageEnd'),
      };
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: this.messageData
      });
      dialogRef.afterOpened().subscribe(_ => {
        setTimeout(() => {
          dialogRef.close();
        }, 6000)
      })
    }
    return this.respuesta;
  }

  saveChanges() {
    this.addComments();

    const user: UserAudifyDTO = {
      usuarioAudify: this.audifyUser.userAudify,
      audify: this.userDetail.audify ? 'S' : 'N',
      informes: this.userDetail.informes ? 'S' : 'N',
      recomendaciones: this.userDetail.recomendaciones ? 'S' : 'N',
      planificacion: this.userDetail.planificacion ? 'S' : 'N',
      envioCorreo: this.userDetail.envioCorreo ? 'S' : 'N',
      mantenimiento: this.userDetail.mantenimiento ? 'S' : 'N',
      administracion: this.userDetail.administracion ? 'S' : 'N',
      vip: this.userDetail.vip ? 'S' : 'N',
      push: this.userDetail.push ? 'S' : 'N',
      correo: this.userDetail.correo ? 'S' : 'N',
      cco: this.userDetail.cco,
      usuarioInterno: this.userDetail.userInterno ? 'S' : 'N',
      comentarios: this.userDetail.comentarios,
      remitente: this.userDetail.remitente
    }

    this.userManagementService.registroUsuarioAudify(user).subscribe(res => {
      if (res.resultado.toLowerCase() === 'ok') {
        this._snackBar.open(this.translate.instant('AUDIFY.MODAL.saveUsuario'));
        this.search()
        this.close()
      } else {
        this._snackBar.open(this.translate.instant('AUDIFY.MODAL.saveusuarioKo'));
        this.close()
      }
      this.close();
    });
  }

  close(): void {
    this.dialogRef.close()
  }

  search() {
    const search: SearchAudify = {
      nombre: null,
      tipoAcceso: null,
      permisos: null,
      geografia: null,
      division: null,
      usuariosBaja: false
    };
    this.userManagementService.getUsers(this.data.search).pipe(take(1)).subscribe(res => {

      if (res) {
        this.dataUser.emit(res);
      }

    });
  }

  openResetPasswordModal(): void {
    this.messageData = {
      title: this.translate.instant('AUDIFY.MODAL.newTempCodeTitle'),
      textBody:
        this.translate.instant('AUDIFY.MODAL.newTempCodeMessage') +
        '\n' +
        this.userDetail.userId +
        ' - ' +
        this.userDetail.userName,
      label: this.translate.instant('AUDIFY.MODAL.newTempCodeLabel'),
      buttons: [
        this.translate.instant('AUDIFY.MODAL.accept'),
        this.translate.instant('AUDIFY.MODAL.cancel'),
      ],
      input: true,
    };
    const dialogRef = this.dialog.open(ModalMessageComponent, { data: this.messageData });

    dialogRef.afterClosed().subscribe(res => {
      if (res != '') {
        const encrypted = this.aesHelper.encrypt(res);
        this.userDetail.codpas = encrypted;

        this.userManagementService.cambiarPassword(this.userDetail).subscribe(res => {
          if (res.estado.toUpperCase() == 'KO') {
            this._snackBar.open(this.translate.instant(res.descripcionResultado, { value: this.userDetail.userId }));
          }
        });
      }
    });

  }

  public resolveSlider(key: string) {
    this.resolveSwitch1(key);
    this.resolveSwitch2(key);
    this.resolveSwitch3(key);
  }

  private resolveSwitch1(key: string) {
    if (key === 'audify' || key === 'informes' || key === 'mantenimiento' || key === 'recomendaciones'
      || key === 'administracion' || key === 'planificacion') {
      this.userDetail[key] = this.audifyUser[key] === "S" ? true : false;
    }
  }
  private resolveSwitch2(key: string) {
    if (key === 'envioCorreo' || key === 'vip' || key === 'push' || key === 'correo' || key === 'userInterno') {
      this.userDetail[key] = this.audifyUser[key] === "S" ? true : false;
    }
  }
  private resolveSwitch3(key: string) {
    if (key === 'cco' || key === 'remitente' || key === 'comentarios') {
      this.userDetail[key] = this.audifyUser[key];
    }
  }

  private initSliders(): void {
    for (const key in this.audifyUser) {
      if (Object.prototype.hasOwnProperty.call(this.audifyUser, key)) {
        this.resolveSlider(key);
      }
    }
  }

  private initAriesVisibility(): void {
    this.userManagementService.getUsuarioAries(this.userData.userId).pipe(take(1)).subscribe(res => {
      let ariesVisibility: AriesVisibility[] = [];
      ariesVisibility = res;
      ariesVisibility.forEach(item => {
        this.userDetail.ariesVisibilityData.push(item.entityName);
      });
    })
  }

  private getUserData(): void {
    this.userManagementService.getDatosUsuario(this.userData.userId).subscribe(res => {
      this.userDetail.userId = res.userId;
      this.userDetail.userName = res.userName;
      this.userDetail.email = res.email;
      this.userDetail.userDFS = res.userDFS;
    })
  }

  private getUserAudifyData(): void {
    this.userManagementService.getUsersAudify(this.userData.userId).pipe(take(1)).subscribe(res => {

      if (res) {
        this.audifyUser = res;
        if (this.audifyUser.audify === 'S') {
          this.initSliders();
        }
      }
      this.chargeUser();
    })
  }

  auto_grow(element: any) {
    element.style.height = "5px";
    element.style.height = (element.scrollHeight) + "px";
  }

  getDate(): string {
    const date: Date = new Date()
    const day: number = date.getDate();
    const month: number = date.getMonth() + 1;
    const year: number = date.getFullYear();
    let stringDate: string = '';
    if (day < 10) {
      stringDate += '0' + day + '/'; //agrega cero si el menor de 10
    } else {
      stringDate += day + '/';
    }

    if (month < 10) {
      stringDate += '0' + month + '/'//agrega cero si el menor de 10
    } else {
      stringDate += month + '/';
    }
    return (stringDate + year.toString());
  }
}
