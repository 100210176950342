import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../app/api/api.service';

@Injectable({ providedIn: 'root' })
export class TermsService {
  private baseUrl = '/api/condicionesUso/getCondicionesUso';

  constructor(private api: ApiService) {}

  private httpOptions = {
    headers: new HttpHeaders({
      Accept: 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    responseType: 'text',
  };

  getTermsText(): Observable<string> {
    return this.api.get<string>(this.baseUrl, this.httpOptions);
  }
}
