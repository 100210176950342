import { HttpClient, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Environment } from '../../../../../environments/environment';
import { ErrorService } from '../error/error.service';
import { ENVIRONMENT } from '../../../constants/global-tokens';
import { RequestOptions } from '../../../http/request-options';
import { isProblemDetailsError } from '../../../http/validation-error';

@Injectable()
export class ApiService {
  private host: string;

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private errorService: ErrorService,
    protected http: HttpClient,
  ) {
    this.host = environment.API_URL;
  }

  delete<T>(
    url: string,
    options?: RequestOptions & { observe?: 'response' },
    showValidationErrorMessage?: boolean,
  ): Observable<HttpResponse<T>>;

  delete<T>(
    url: string,
    options?: RequestOptions & { observe?: 'body' },
    showValidationErrorMessage?: boolean,
  ): Observable<T>;

  delete<T>(
    url: string,
    options?: RequestOptions & { observe?: 'response' | 'body' },
    showValidationErrorMessage = true,
  ): Observable<any> {
    return this.http
      .delete<T>(`${this.host}${url}`, options as any)
      .pipe(catchError(error => this.handleError(error, showValidationErrorMessage)));
  }

  get<T>(url: string, option?: RequestOptions): Observable<T>;

  get<T>(
    url: string,
    option?: RequestOptions & { observe: 'response' },
  ): Observable<HttpResponse<T>>;

  get(
    url: string,
    option?: RequestOptions & { responseType: 'arraybuffer' },
  ): Observable<ArrayBuffer>;

  get(url: string, option?: RequestOptions & { responseType: 'blob' }): Observable<Blob>;

  get(
    url: string,
    option?: RequestOptions & { responseType: 'blob' } & { observe: 'response' },
  ): Observable<HttpResponse<Blob>>;

  get<T>(
    url: string,
    option?: RequestOptions & { observe?: 'body' | 'events' | 'response' } & {
      responseType?: 'blob' | 'arraybuffer';
    },
  ): Observable<HttpResponse<T> | T | ArrayBuffer | Blob> {
    return this.http
      .get(`${this.host}${url}`, option as any)
      .pipe(catchError(error => this.handleError(error)));
  }

  patch<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: 'response' },
  ): Observable<HttpResponse<T>>;

  patch<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: 'events' },
  ): Observable<HttpEvent<T>>;

  patch<T>(url: string, body: any, options?: RequestOptions & { observe?: 'body' }): Observable<T>;

  patch<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: 'body' | 'events' | 'response' },
  ): Observable<HttpEvent<T> | HttpResponse<T> | T> {
    return this.http
      .patch<T>(`${this.host}${url}`, body, options as any)
      .pipe(catchError(error => this.handleError(error)));
  }

  post<T>(
    url: string,
    body: any,
    options?: RequestOptions,
    showValidationErrorMessage?: boolean,
  ): Observable<T>;

  post<T>(
    url: string,
    body: any,
    options?: RequestOptions & { responseType: 'blob' } & { observe: 'response' },
    showValidationErrorMessage?: boolean,
  ): Observable<HttpResponse<Blob>>;

  post<T>(
    url: string,
    body: any,
    option?: RequestOptions & { observe: 'events' },
    showValidationErrorMessage?: boolean,
  ): Observable<HttpEvent<T>>;

  post<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: 'body' | 'events' | 'response' },
    showValidationErrorMessage = true,
  ): Observable<HttpEvent<T> | HttpResponse<T> | T> {
    return this.http
      .post<T>(`${this.host}${url}`, body, options as any)
      .pipe(catchError(error => this.handleError(error, showValidationErrorMessage)));
  }

  put<T>(
    url: string,
    body: any,
    options?: RequestOptions,
    showValidationErrorMessage?: boolean,
  ): Observable<T>;

  put<T>(
    url: string,
    body: any,
    option?: RequestOptions & { observe: 'events' },
    showValidationErrorMessage?: boolean,
  ): Observable<HttpEvent<T>>;

  put<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: 'events' | 'response' | 'body' },
  ): Observable<HttpEvent<T> | T> {
    return this.http
      .put<T>(`${this.host}${url}`, body, options as any)
      .pipe(catchError(error => this.handleError(error)));
  }

  private handleError(err: HttpErrorResponse, showValidationErrorMessage?: boolean) {
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      // tslint:disable-next-line:no-console
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // tslint:disable-next-line:no-console
      console.log(
        `Backend returned code ${err.status}, body was:`,
        typeof err.error === 'string' ? 'HTML body' : err.error,
      );
    }

    if (err.status === 401 || showValidationErrorMessage) {
      this.errorService.next(err.error);
    }

    return throwError((isProblemDetailsError(err.error) && err.error) || err);
  }
}
