<div *ngIf="loadingBarService.value$ | async" class="home__spinner d-flex">
  <app-spinner></app-spinner>
</div>
<div class="app-container">
  <div class="modal-detail app-container__inner">
    <div class="modal-detail__head">
      <div class="modal-head__title">
        <h2 [innerHTML]="userDetail.userId? userDetail.userId + ' - ' + userDetail.userName : userDetail.userName"></h2>
        <h3 [innerHTML]="userDetail.email"></h3>
      </div>
      <div class="modal-head__slide-toggle">
        <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.audifyAccess' | translate"></mat-label>
        <mat-slide-toggle class="big-slide" id="audifyAccess" (change)="changeAudifyAccess($event)"
        [checked]="userDetail.audify"></mat-slide-toggle>
      </div>
    </div>
    <div class="modal-detail__content">
      <section class="aries-visibility">
        <h3 [innerHTML]="'AUDIFY.USER_DETAIL.ariesVisibility' | translate"></h3>
        <p>
          DFS:
          <span [innerHTML]="userDetail.userDFS"> </span><!-- Change by real data-->
        </p>
        <textarea class="custom-scrollbar" readonly
          [value]="userDetail.ambitoConsulta + '\n' + userDetail.ariesVisibilityData.join('\n')"></textarea>
      </section>
      <form class="comments" [formGroup]="formComments">
        <section [ngClass]="{ disabled: !userDetail.audify }">
          <h3 [innerHTML]="'AUDIFY.USER_DETAIL.comments' | translate"></h3>
          <textarea class="custom-scrollbar" formControlName="comentarios" [(ngModel)]="userDetail.comentarios"
            [value]="userDetail.comentarios"></textarea>
        </section>
      </form>
      <section [ngClass]="{ options: true, disabled: !userDetail.audify }">
        <div class="options__groups">
          <h4 [innerHTML]="'AUDIFY.USER_DETAIL.permits' | translate"></h4>
          <form class="permits__sliders" [formGroup]="formPermitsSliders">
            <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.reports' | translate"></mat-label>
            <div class="slider">
              <mat-slide-toggle id="reports" formControlName="informes" [(ngModel)]="userDetail.informes" [checked]="userDetail.informes"></mat-slide-toggle>
            </div>
            <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.maintenance' | translate"></mat-label>
            <div class="slider">
              <mat-slide-toggle id="maintenance" formControlName="mantenimiento" [(ngModel)]="userDetail.mantenimiento" [checked]="userDetail.mantenimiento"></mat-slide-toggle>
            </div>
            <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.recommendations' | translate"></mat-label>
            <div class="slider">
              <mat-slide-toggle id="recommendations" formControlName="recomendaciones" [(ngModel)]="userDetail.recomendaciones" [checked]="userDetail.recomendaciones"></mat-slide-toggle>
            </div>
            <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.administration' | translate"></mat-label>
            <div class="slider">
              <mat-slide-toggle id="administration" formControlName="administracion" [(ngModel)]="userDetail.administracion" [checked]="userDetail.administracion"></mat-slide-toggle>
            </div>
            <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.planning' | translate"></mat-label>
            <div class="slider">
              <mat-slide-toggle id="planning" formControlName="planificacion" [(ngModel)]="userDetail.planificacion" [checked]="userDetail.planificacion"></mat-slide-toggle>
            </div>
            <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.vip' | translate"></mat-label>
            <div class="slider">
              <mat-slide-toggle id="vip" formControlName="vip" [(ngModel)]="userDetail.vip" [checked]="userDetail.vip"></mat-slide-toggle>
            </div>
            <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.mailing' | translate"></mat-label>
            <div class="slider">
              <mat-slide-toggle id="mailing" formControlName="envioCorreo" [(ngModel)]="userDetail.envioCorreo" [checked]="userDetail.envioCorreo"></mat-slide-toggle>
            </div>
          </form>
        </div>
        <div class="options__groups">
          <h4 [innerHTML]="'AUDIFY.USER_DETAIL.notifications' | translate"></h4>
          <form class="notifications__sliders" [formGroup]="formNotificationSliders">
            <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.push' | translate"></mat-label>
            <div class="slider">
              <mat-slide-toggle id="push" formControlName="push" [(ngModel)]="userDetail.push" [checked]="userDetail.push"></mat-slide-toggle>
            </div>
            <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.mail' | translate"></mat-label>
            <div class="slider">
              <mat-slide-toggle id="mail" formControlName="correo" [(ngModel)]="userDetail.correo" [checked]="userDetail.correo"></mat-slide-toggle>
            </div>
            <mat-label class="mailing" [innerHTML]="'AUDIFY.USER_DETAIL.sender' | translate"></mat-label>
            <input matInput [value]="userDetail.remitente" [(ngModel)]="userDetail.remitente" formControlName="remitente" />
            <mat-label class="mailing" [innerHTML]="'AUDIFY.USER_DETAIL.copy' | translate"></mat-label>
            <textarea oninput="auto_grow(this)" formControlName="cco" [value]="userDetail.cco" [(ngModel)]="userDetail.cco"></textarea>
          </form>
        </div>
        <div class="reset-section">
          <div class="slide-toggle">
            <form class="user_interno" [formGroup]="formUserInternoSlider">
              <mat-label [innerHTML]="'AUDIFY.USER_DETAIL.internalUser' | translate"></mat-label>
              <mat-slide-toggle class="big-slide" id="internalUser"[checked]="userDetail.userInterno"
                formControlName="userInterno" (change)="changeTypeUser($event)"></mat-slide-toggle>
            </form>
          </div>
          <button mat-button *ngIf="!userDetail.userInterno" class="reset-pass btn-color-white"
            [innerHTML]="'AUDIFY.USER_DETAIL.resetpass' | translate" (click)="openResetPasswordModal()">
          </button>
        </div>
      </section>
    </div>
    <div class="modal-detail__btns">
      <button mat-button type="button" class="btn-color-red btn-text-left" (click)="borrarDispositivos()">
        <mat-icon matSuffix style="font-size: 1.5em">phonelink_erase</mat-icon>
        {{ 'AUDIFY.USER_DETAIL.delete' | translate }}
      </button>
      <button mat-button class="btn-color-red" [innerHTML]="'AUDIFY.MODAL.accept' | translate"
        (click)="saveChanges()"></button>
      <button mat-button class="btn-color-white" [innerHTML]="'AUDIFY.MODAL.cancel' | translate"
        (click)="close()"></button>
    </div>
  </div>
</div>
