import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthService } from '../../services/app/auth/auth.service';
import { TermsService } from '../../services/entities/terms.service';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsModalComponent implements OnInit {
  hasAccept = false;
  body = '';

  constructor(
    public dialogRef: MatDialogRef<TermsModalComponent>,
    private authService: AuthService,
    private ref: ChangeDetectorRef,
    private termsService: TermsService,
  ) {}

  ngOnInit(): void {
    this.getText();
  }

  getText() {
    this.termsService.getTermsText().subscribe(res => {
      this.body = res;
      this.ref.markForCheck();
      const modalEl = document.querySelector('.mat-dialog-container');
      setTimeout(() => modalEl?.scrollTo(0, 0), 100);
    });
  }

  onClick(): void {
    this.acceptTerms();
    this.dialogRef.close();
  }
  acceptTerms() {
    this.authService.aceptarTerm();
  }
}
