import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SharedModule } from '../../shared/shared.module';
import { UserDetailComponent } from '../user-detail/user-detail.component';
import { UserDetailModule } from '../user-detail/user-detail.module';

import { UserManagementComponent } from './user-management.component';

const routes: Routes = [
{ path: '', component: UserManagementComponent },
{ path: ':id', component: UserDetailComponent }
];

export function getTranslation(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: getTranslation,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    SharedModule,
    UserDetailModule,
    RouterModule.forChild(routes)
  ],
  declarations: [UserManagementComponent],
  exports: [UserManagementComponent],
})
export class UserManagementModule {}
