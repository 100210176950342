import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/app/api/api.service';
import { AccessFilterCombo, PermissFilterCombo,GeographiesFilterCombo, DivisionFilterCombo, SearchAudify, DataUsers, TableHaders, AriesVisibility, UserData, UserAudify, UserAudifyDTO, UserDetailDTO, CodigoActivacionDTO } from 'src/app/shared/models/audify';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  private urlUsers : string = '/api/admin/users/';

  constructor(private http: ApiService) { }

  getTipoAccesos(): Observable<AccessFilterCombo[]>{
    return this.http.get<AccessFilterCombo[]>('/api/gestionUsuarios/getTiposAcceso');
  }
  getPermisos(): Observable<PermissFilterCombo[]>{
    return this.http.get<PermissFilterCombo[]>('/api/gestionUsuarios/getPermisos');
  }
  getGeografias(): Observable<GeographiesFilterCombo[]>{
    return this.http.get<GeographiesFilterCombo[]>('/api/gestionUsuarios/getGeografias');
  }
  getDivisiones(geografia_id: string): Observable<DivisionFilterCombo[]>{
    return this.http.post<DivisionFilterCombo[]>('/api/gestionUsuarios/getDivisiones?geografia_id=' + geografia_id, {});
  }
  getUsers(filter: SearchAudify): Observable<any[]>{
    return this.http.post<any[]>('/api/gestionUsuarios/getUsuariosAudify/',filter);
  }
  getTableHeaders(): Observable<TableHaders[]>{
    return this.http.get<TableHaders[]>('/api/gestionUsuarios/getGeografias');
  }
  getUsuarioAries(idUser: string): Observable<AriesVisibility[]>{
    return this.http.post<AriesVisibility[]>('/api/accesoAudify/getVisibilidadAries?usuarioAries=' + idUser, {});
  }
  getDatosUsuario(idUser: string): Observable<UserData>{
    return this.http.post<UserData>('/api/accesoAudify/getDatosUsuario?usuarioAries=' + idUser, {});
  }
  getUsersAudify(idUser:string): Observable<UserAudify>{
    return this.http.post<UserAudify>('/api/accesoAudify/recuperarUserAudify?usuarioAudify=' + idUser, {});
  }
  getcabecerasXLS(): Observable<any>{
    return this.http.get<any[]>('/api/gestionUsuarios/getcabecerasXLS/');
  }
  getUsersAudifyXLS(filter: SearchAudify): Observable<any[]> {
    return this.http.post<any[]>('/api/gestionUsuarios/getUsuariosAudifyXLS/', filter);
  }
  registroUsuarioAudify(user: UserAudifyDTO): Observable<any> {
    return this.http.post<any>('/api/accesoAudify/registroUsuarioAudify/', user);
  }
  borrarDispositivos(user: UserAudifyDTO): Observable<any> {
    return this.http.post<any>('/api/accesoAudify/borrarDispositivosUsuarioAudify/', user);
  }
  convetirUsuarioInterno(uid: string): Observable<any> {
    return this.http.post<any>(this.urlUsers + uid + '/interno', {});
  }
  convetirUsuarioExterno(user: UserDetailDTO): Observable<any> {
    const uid = user.userId;
    return this.http.post<any>(this.urlUsers + uid + '/externo', user);
  }
  cambiarPassword(user : UserDetailDTO): Observable<any> {
    const uid = user.userId;
    return this.http.post<any>(this.urlUsers + uid + '/cambiarPassword', user);
  }
  desactivarUsuario(uid: string): Observable<any> {
    return this.http.post<any>(this.urlUsers + uid + '/desactivar', {});
  }
  getPrimeraClave(token: string): Observable<CodigoActivacionDTO> {
    return this.http.get<CodigoActivacionDTO>('/api/claveActivacion/getPrimeraClave?token=' + token);
  }
  getCambioClave(token: string): Observable<CodigoActivacionDTO> {
    return this.http.get<CodigoActivacionDTO>('/api/claveActivacion/getCambioClave?token=' + token);
  }
}
