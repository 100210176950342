import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Division } from '../../../../core/models/entities/division';
import { Geography } from '../../../../core/models/entities/geography';

type GeographyModel = Omit<Geography, 'subGeografias'> & {
  allComplete?: boolean;
  collapsed?: boolean;
  checked: boolean;
  subGeografias: GeographyModel[];
};

@Component({
  selector: 'app-filter-modal-mobile',
  templateUrl: './filter-modal-mobile.component.html',
  styleUrls: ['./filter-modal-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FilterModalMobileComponent {
  geographies: GeographyModel[] = [];
  isOpenGeographyMenu = false;
  isOpenAreaMenu = false;
  selectedGeographiesCodes?: string;
  selectedGeographiesNames?: string;
  selectedUserDivision?: number;
  selectedUserDivisionName?: string;
  userDivisions: Division[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    public dialogRef: MatDialogRef<FilterModalMobileComponent>,
    private ref: ChangeDetectorRef,
  ) {
    this.geographies = dialogData.geographies;
    this.userDivisions = dialogData.userDivisions;
    this.selectedUserDivision = dialogData.selectedUserDivision;
    this.selectedUserDivisionName = this.userDivisions?.find(
      d => d.cdDivision === this.selectedUserDivision,
    )?.nbDivision;
    this.selectedGeographiesNames = dialogData.selectedGeographiesNames;
  }

  cleanAll() {
    this.geographies.forEach(geography => this.setAll(geography, false));
    this.updateButton();
    this.onChangeArea(null);
    this.acceptOptions();
  }

  updateAllComplete(geography: GeographyModel) {
    geography.allComplete = geography?.subGeografias.every(t => t.checked) ?? false;
    this.ref.markForCheck();
  }

  someComplete(geography: GeographyModel): boolean {
    if (geography.subGeografias == null) {
      return false;
    }

    return geography.subGeografias.filter(t => t.checked).length > 0 && !geography.allComplete;
  }

  setAll(geography: GeographyModel, checked: boolean) {
    geography.allComplete = checked;
    geography.checked = checked;
    if (!geography.subGeografias) {
      return;
    }

    geography.subGeografias.forEach(t => (t.checked = checked));
    this.ref.markForCheck();
  }

  updateButton() {
    let selected = this.geographies.filter(g => g.checked);
    selected = selected.concat(
      ...this.geographies
        .filter(g => !g.checked)
        .map(g => g.subGeografias.filter(inner => inner.checked)),
    );

    this.selectedGeographiesNames = selected.map(g => g.nbGeografia).join(', ');
  }

  onChangeArea(area: Division | null) {
    if(area === null){
      this.selectedUserDivision = 0;
      this.selectedUserDivisionName = undefined;
    }else{
      this.selectedUserDivision = area.cdDivision;
      this.selectedUserDivisionName = area.nbDivision;
    }
  }

  acceptOptions() {
    const selectedGeographiesCodes = this.getSelectedGeographiesCodes();

    this.dialogRef.close({
      selectedUserDivision: this.selectedUserDivision,
      selectedGeographiesCodes,
    });
  }

  private getSelectedGeographiesCodes() {
    let newSelectedGeographiesCodes = this.geographies.filter(
      g => g.checked && !g.subGeografias.length,
    );
    newSelectedGeographiesCodes = newSelectedGeographiesCodes
      .concat(...this.geographies.map(g => g.subGeografias.filter(inner => inner.checked)))
      .sort((g1, g2) => g1.cdGeografia.localeCompare(g2.cdGeografia));

    return newSelectedGeographiesCodes.map(g => g.cdGeografia).join(',') || undefined;
  }
}
