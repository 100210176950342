import { Injectable } from "@angular/core";
import * as Forge from 'node-forge';
import * as CryptoJS from 'crypto-js';



@Injectable({

    providedIn: 'root',

})

export class AESHelper {
    construct() {}
    encrypt(data : string){
        const key = CryptoJS.lib.WordArray.random(8).toString();
        const iv = CryptoJS.lib.WordArray.random(8).toString();
        const cipher = Forge.cipher.createCipher("AES-GCM", key);
        cipher.start({
            iv : iv
        })
        cipher.update(Forge.util.createBuffer(data));
        cipher.finish();
        const encrypted = cipher.output;
        const base64Encoded = Forge.util.encode64(encrypted.data);
        const tag = cipher.mode.tag;
        const tagBase64 = Forge.util.encode64(tag.data);
        return key+iv+tagBase64+base64Encoded;
    }
    
}