import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return forkJoin([
      this.authService.isAuthorized(),
      this.authService.isUserValid(),
      this.authService.isTokenTakenFromQueryParam(),
    ]).pipe(
      switchMap(([isAuthorized, isUserValid, isTokenTakenFromQueryParam]) => {
        if (isTokenTakenFromQueryParam) {
          localStorage.removeItem('audify:isOpenedNotifications');
        }

        if (!isAuthorized || !isUserValid) {
          // return of(false);
          this.router.navigate(['/notAllowed'], {
            skipLocationChange: true, // minimal effect. see https://github.com/angular/angular/issues/17004
            queryParams: {
              url: state.url,
            },
          });
        }

        return this.authService.refreshToken();
      }),
      catchError(() => {
        this.router.navigate(['/notAllowed'], {
          skipLocationChange: true, // minimal effect. see https://github.com/angular/angular/issues/17004
          queryParams: {
            url: state.url,
            title: 'ERROR.TITLE',
            subtitle: 'ERROR.SUBTITLE',
          },
        });

        return of(true);
      }),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
