import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Geography } from 'src/app/core/models/entities/geography';
import { ApiService } from 'src/app/core/services/app/api/api.service';
import { LoadingService } from 'src/app/core/services/app/loading/loading.service';
import { indicate } from 'src/app/core/utils/rx-operators';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  loading = new LoadingService();

  private readonly baseUrl = '/api/language';

  constructor(private api: ApiService) {}

  getUserGeographies(lang: string): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'text/html, application/xhtml+xml, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      responseType: 'text',
    };

    return this.api
      .get<string>(`${this.baseUrl}/changeLanguage/${lang}`, httpOptions)
      .pipe(indicate(this.loading));
  }
}
