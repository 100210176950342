import { HttpErrorResponse } from '@angular/common/http';

import { isNil } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../services/app/notification/notification.service';
import {
  isProblemDetailsError,
  isValidationError,
  ProblemDetails,
  ValidationError,
} from '../../http/validation-error';
import { AuthService } from '../app/auth/auth.service';

export const MSG_ERROR = 'Por favor, intente nuevamente o contacte al administrador del sistema.';
export const MSG_ERROR_ING = 'Please try again or contact your system administrator.';
export const MSG_ERROR_PRT = 'Por favor tente novamente ou contacte o administrador do seu sistema.';

export function handleError(
  error: ProblemDetails | ValidationError | Error | HttpErrorResponse,
  notification: NotificationService
) {
  let idioma = notification.getLanguage();

  let message = MSG_ERROR;

  if(idioma == 'ENG'){
    message = MSG_ERROR_ING;
  }else if (idioma == 'POR'){
    message = MSG_ERROR_PRT;
  }

  if (isValidationError(error)) {
    let code = error.invalid_params.find(e => isNil(e.name))?.code;
    if (!code) {
      code = error.invalid_params.find(e => !isNil(e.name))?.code;
    }

    message = code ?? MSG_ERROR;
  } else if (isProblemDetailsError(error)) {
    message = error.code ?? MSG_ERROR;
  }

  notification.showMessage(message);

}
