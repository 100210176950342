import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { Event, Router, RouterModule, Routes, Scroll } from '@angular/router';

import { filter } from 'rxjs/operators';

import { AuthGuard } from './core/guards/auth.guard';
import { FullLayoutComponent } from './layouts';
import { TermsGuard } from './core/guards/terms.guard';
import { UserManagementComponent as AudifyManagementComponent, UserManagementComponent } from './audify/user-management/user-management.component';
const routes: Routes = [
  {
    path: 'notAllowed',
    loadChildren: () =>
      import('./core/components/not-allowed/not-allowed.module').then(m => m.NotAllowedModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [TermsGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./dashboard/home/home.module').then(m => m.HomeModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'trabajopage',
        loadChildren: () =>
          import('./dashboard/trabajopage/trabajopage.module').then(m => m.TrabajopageModule),
        canActivateChild: [AuthGuard],
        data: { option: 'ratings' },
      },
      {
        path: 'recomendaciones',
        loadChildren: () =>
          import('./dashboard/recomendaciones/recomendaciones.module').then(
            m => m.RecomendacionesModule,
          ),
        canActivateChild: [AuthGuard],
        data: { option: 'recomendaciones' },
      },
      {
        path: 'ultimas-distribuciones',
        loadChildren: () =>
          import('./dashboard/ultimas-distribuciones/ultimas-distribuciones.module').then(
            m => m.UltimasDistribucionesModule,
          ),
        canActivateChild: [AuthGuard],
        data: { option: 'emisiones' },
      },
      {
        path: 'trabajos-planificados',
        loadChildren: () =>
          import('./dashboard/trabajos-planificados/trabajos-planificados.module').then(
            m => m.TrabajosPlanificadosModule,
          ),
        canActivateChild: [AuthGuard],
        data: { option: 'planificacion' },
      },
      {
        path: 'evolucion-recomendaciones',
        loadChildren: () =>
          import('./dashboard/evolucion-recomendaciones/evolucion-recomendaciones.module').then(
            m => m.EvolucionRecomendacionesModule,
          ),
        canActivateChild: [AuthGuard],
        data: { option: 'recomendaciones' },
      },
      {
        path: '404',
        loadChildren: () =>
          import('./core/components/not-found/not-found.module').then(m => m.NotFoundModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'user-management',
        /*loadChildren: () =>
          import('./audify/user-management/user-management.module').then(m => m.UserManagementModule),*/
        canActivateChild: [AuthGuard],
        component: UserManagementComponent
      },
      { path: '**', redirectTo: '/404' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: object,
    router: Router,
    viewportScroller: ViewportScroller,
  ) {
    router.events.pipe(filter((e: Event): e is Scroll => e instanceof Scroll)).subscribe(e => {
      if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        // TODO: ViewportScroller.scrollToAnchor method have problems
        const element = document.querySelector(`#${e.anchor}`);
        if (element) {
          element.scrollIntoView();
        }
      } else {
        // forward navigation
        // TODO: ViewportScroller.scrollToPosition method have problems with sidenav
        // viewportScroller.scrollToPosition([0, 0]);
        document.getElementsByTagName('mat-sidenav-content')[0]?.scrollTo(0, 0);
      }
    });
  }
}
