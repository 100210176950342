import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Fichero } from 'src/app/core/models/entities/fichero';
import { InformeEstatico } from 'src/app/core/models/entities/InformeEstatico';
import { ApiService } from 'src/app/core/services/app/api/api.service';
import { PdfService } from 'src/app/core/services/app/pdf/pdf.service';
import { downloadFile, getFile, isMobile } from 'src/app/core/utils/files';

@Injectable({
  providedIn: 'root',
})
export class InformeEstaticoService {
  private readonly baseUrl = '/api/informeestatico';

  constructor(private api: ApiService, private pdfService: PdfService) {}

  getAllInformeEstatico(): Observable<InformeEstatico[]> {
    return this.api.get<InformeEstatico[]>(`${this.baseUrl}/getAllInformeEstatico`);
  }

  getPdf(rgInforme: number) {
    const headers = new HttpHeaders({
      Accept: '*/*',
    });
    return this.api
      .get<Fichero>(`${this.baseUrl}/pdf/${rgInforme}.pdf`, { headers })
      .pipe(
        tap(data => {
          if (isMobile()) {
            downloadFile(data);
          } else {
            const [file, filename] = getFile(data);
            this.pdfService.openFileInExternalWindow(file, filename);
          }
        }),
      );
  }

  // notetoself: move to proper service provider.
  getReport(rgTrabajo: number) {
    const id = rgTrabajo;
    const url = `/api/reports/pdf`;
    const headers = new HttpHeaders({
      Accept: '*/*',
    });
    return this.api.post<Fichero>(url, { id }, { headers }).pipe(
      tap(data => {
        if (isMobile()) {
          downloadFile(data);
        } else {
          const [file, filename] = getFile(data);
          this.pdfService.openFileInExternalWindow(file, filename);
        }
      }),
    );
  }
}
