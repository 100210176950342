<mat-toolbar class="app-container header">
  <button mat-icon-button class="back-button" (click)="dialogRef.close()">
    <mat-icon class="white-icon">arrow_back</mat-icon>
  </button>

  <span class="flex-spacer mat-headline m-0 d-flex justify-content-center text-white">{{
    'DASHBOARD.FILTERS_LABEL' | translate
  }}</span>
</mat-toolbar>

<div class="flex-spacer filter-modal__body">
  <button class="filter-modal__body-btn mb-2" [class.header__button--active]="isOpenGeographyMenu" (click)="isOpenGeographyMenu = !isOpenGeographyMenu; isOpenAreaMenu = false">
    <img src="assets/func-146-access-to-web-red.png" width="40" height="40" />
    <div class="filter-modal__body-btn-text">
      <p class="m-0">{{ 'DASHBOARD.GEOGRAPHY_LABEL' | translate }}</p>
      <p
        class="m-0 header__button-selected-item text-truncate"
        title="{{ selectedGeographiesNames || 'DASHBOARD.SELECTION_LABEL' | translate }}"
      >
        <strong>{{ selectedGeographiesNames || 'DASHBOARD.SELECTION_LABEL' | translate }}</strong>
      </p>
    </div>
    <div class="flex-spacer"></div>
    <div
      class="filter-modal__body-btn-arrow"
      [class.filter-modal__body-btn-arrow--expanded]="isOpenGeographyMenu"
    ></div>
  </button>

  <ng-container *ngIf="isOpenGeographyMenu">
    <ng-template #emptyBox>
      <div [style.width.px]="24"></div>
    </ng-template>
    <div *ngFor="let geography of geographies; let index = index" class="filter-modal__body-list-item">
      <mat-divider [style.marginLeft.px]="-56"></mat-divider>

      <div class="mt-2 d-flex">
        <div *ngIf="geography.subGeografias?.length" class="header__geography-menu-arrow mt-1" [class.header__geography-menu-arrow--expanded]="!geography.collapsed" (click)="geography.collapsed = !geography.collapsed"></div>

        <div *ngIf="!geography.subGeografias?.length" class="header__geography-menu-arrow mt-1" style="visibility: hidden"></div>

        <mat-checkbox class="santander-checkbox col pe-0 ps-1" color="primary" labelPosition="before" [checked]="geography.allComplete" [indeterminate]="someComplete(geography)" (change)="setAll(geography, $event.checked); updateButton()">
          {{ geography.nbGeografia }}
        </mat-checkbox>
      </div>
      <div *ngIf="!geography.collapsed">
        <ul class="mb-0 ps-1 header__geography-menu-list">
          <ng-container *ngFor="let subGeography of geography.subGeografias; let first = first">
            <mat-divider *ngIf="first" [style.marginLeft.px]="-88"></mat-divider>
            <li class="mt-2">
              <mat-checkbox class="santander-checkbox filter-modal__body-checkbox" color="primary" labelPosition="before" [checked]="subGeography.checked" (change)="
                  subGeography.checked = $event.checked;
                  updateAllComplete(geography);
                  updateButton()
                ">
                {{ subGeography.nbGeografia }}
              </mat-checkbox>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-container>

  <button class="filter-modal__body-btn mb-2" [class.header__button--active]="isOpenAreaMenu" (click)="isOpenAreaMenu = !isOpenAreaMenu; isOpenGeographyMenu = false">
    <img src="assets/serv-006-building-red.png" width="40" height="40" />
    <div class="filter-modal__body-btn-text">
      <p class="m-0">{{ 'DASHBOARD.AREA_LABEL' | translate }}</p>
      <p
        class="m-0 header__button-selected-item text-truncate"
        title="{{ selectedUserDivisionName || 'DASHBOARD.SELECTION_LABEL' | translate }}"
      >
        <strong>{{ selectedUserDivisionName || 'DASHBOARD.SELECTION_LABEL' | translate }}</strong>
      </p>
    </div>
    <div class="flex-spacer"></div>
    <div
      class="filter-modal__body-btn-arrow"
      [class.filter-modal__body-btn-arrow--expanded]="isOpenAreaMenu"
    ></div>
  </button>

  <mat-radio-group *ngIf="isOpenAreaMenu" class="filter-modal__body-area-menu" [value]="selectedUserDivision" (click)="$event.stopPropagation()">
    <mat-divider class="mb-2" [style.marginLeft.px]="-56"></mat-divider>
    <ul class="ps-2 me-1">
      <li *ngFor="let area of userDivisions; let first = first; let last = last" class="mt-2">
        <mat-radio-button color="primary" [class.mb-2]="!last" [value]="area.cdDivision" (click)="onChangeArea(area)">{{ area.nbDivision }}</mat-radio-button>
      </li>
    </ul>
  </mat-radio-group>
</div>

<div class="app-container filter-modal__footer">
  <button color="primary" mat-stroked-button class="santander-stroked-btn filter-modal__btn me-2" (click)="cleanAll()">
    {{ 'BUTTON_ACTIONS.CLEAN' | translate }}
  </button>
  <button mat-raised-button class="santander-raised-btn filter-modal__btn" color="primary" (click)="acceptOptions()">
    {{ 'BUTTON_ACTIONS.OK' | translate }}
  </button>
</div>