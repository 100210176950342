import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';

import { ModalMessageModule } from '../modal-message/modal-message.module';

import { UserDetailComponent } from './user-detail.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModalMessageModule,
    ReactiveFormsModule
  ],
  declarations: [UserDetailComponent],
  exports: [UserDetailComponent],
})
export class UserDetailModule {}
