import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable, Inject, SecurityContext } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { ConfigUsuario } from 'src/app/core/models/entities/config-usuario';
import { isExternal } from 'src/app/core/utils/files';
import { ApiService } from '../api/api.service';
import { STORAGE_ISMOBILE, STORAGE_TOKEN, STORAGE_ISEXTERNAL } from '../../../constants/storage-keys';
import { StorageService } from '../storage/storage.service';
import { isMobile } from 'src/app/core/utils/files';
import { DOCUMENT } from '@angular/common';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { Router } from '@angular/router';
import { UserAudify } from '../../../../shared/models/audify';

const httpHeaders: HttpHeaders = new HttpHeaders({ Authorization: `Bearer ${STORAGE_TOKEN}` });

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // tslint:disable-next-line:variable-name
  private _logout$ = new Subject<void>();
  private redirect: boolean = false;
  logout$ = this._logout$.asObservable();

  // tslint:disable-next-line:variable-name
  private _userConfig$ = new BehaviorSubject<ConfigUsuario | undefined>(undefined);

  userConfig$ = this._userConfig$.asObservable();

  private readonly configUrl = '/config.json';
  private readonly configUsuarioURL = '/api/rest/config/getConfigUsuario';
  private swAcceso = false;

  public semaforo: boolean = false;

  constructor(
    private api: ApiService,
    private storageService: StorageService,
    private readonly dompurifySanitizer: NgDompurifySanitizer,private router: Router,
    @Inject(DOCUMENT) private document: Document, private http: HttpClient) {}

  getAccessToken() {
    const urlParams = new URLSearchParams(document.location.search);
    const token = ((window as any)?.token as string | undefined) || urlParams.get('token');
    if (token) {
      this.saveToken(token);
    }
    this.saveOS();
    if(isExternal()){
      this.saveExt();
    }

    return this.storageService.get(STORAGE_TOKEN);
  }

  isTokenTakenFromQueryParam() {
    const token =
      !!((window as any)?.token as string | undefined) ||
      new URLSearchParams(document.location.search).has('token');
    return of(token);
  }

  isAuthorized() {
    return this.getAccessToken().pipe(map(token => !!token));
  }

  isUserValid() {
    return this.api.get<ConfigUsuario>(this.configUsuarioURL).pipe(
      tap(userConfig => this._userConfig$.next(userConfig)),
      map(
        userConfig =>
          userConfig.indAcceso && (userConfig.swAcceso === 'S' || userConfig.swAcceso === 'C'),
      ),
    );
  }

  isUserAdmin() {
    return this.api.get<ConfigUsuario>(this.configUsuarioURL).pipe(
      tap(userConfig => this._userConfig$.next(userConfig)),
      map(
        userConfig => userConfig,
      ),
    );
  }
  getConfig() {
    return this.http.get(this.configUrl).subscribe(
      (response: any) => {
        this.redirect = response.redirect;
      });
  }
  accessConfig(sidebar: boolean, app:boolean) {
    return new Promise((resolve, reject) => {
      // Llamada al fichero de configuración
      this.http.get(this.configUrl).subscribe(
        (response: any) => {
          if (response.redirect) {
            if(sidebar){
              this.logout();
            }
            this.document.location.href = this.dompurifySanitizer.sanitize(SecurityContext.HTML, response.loginUrl);
            resolve(response);
          }
          else {
            if(app){
              this.semaforo = true;
            }else {
              if(sidebar){
                this.logout();
              }
              this.router.navigate(['/notAllowed'], {
                skipLocationChange: true, // minimal effect. see https://github.com/angular/angular/issues/17004
              });
            }

          }
        },
        (error: any) => {
          reject();
        },
      );
    });
  }

  aceptarTerm() {
    return this.api
      .get<any>('/api/rest/config/aceptarCondiciones', { headers: httpHeaders })
      .subscribe(res => {
        this.api
          .get<ConfigUsuario>(this.configUsuarioURL)
          .subscribe(userConfig => this._userConfig$.next(userConfig));
      });
  }

  async isUserContainsswAcceso(): Promise<ConfigUsuario> {
    return await this.api.get<ConfigUsuario>(this.configUsuarioURL).toPromise();
  }

  logout() {
    if (isMobile()) {
      this.storageService.clearAll();
      this._logout$.next();

      (window as any).webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action:'logout'}));
    } else {
      this.storageService.clearAll();
      this._logout$.next();
    }
  }

  refreshToken(): Observable<boolean> {
    if(isExternal()){
      return this.getAccessToken().pipe(
        switchMap(token => (!!token ? this.refreshTokenFromApi() : of(false))),
      );
    }else{
      return this.getAccessToken().pipe(
        switchMap(token => (!!token ? this.refreshTokenFromApiInterno() : of(false))),
      );
    }

  }


  private refreshTokenFromApi() {
    this.saveOS();
    this.saveExt();
    return this.api
      .post<{ token: string }>(`/api/tokens/bks/refresh`, { token: '' })
      .pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap(({ token }) => this.saveToken(token)),
        mapTo(true),
        catchError(() => of(false)),
      );
      ;
  }

  private refreshTokenFromApiInterno() {
    this.saveOS();
    return this.api
      .post<{ token: string }>(`/api/admin/internoToken/refresh`, { token: '' })
      .pipe(
        tap(({ token }) => this.saveToken(token)),
        mapTo(true),
        catchError(() => of(false)),
      );

  }

  private saveToken(token: string | undefined | null) {
    if (token) {
      this.storageService.set(STORAGE_TOKEN, token);
    }
  }
  private saveOS() {
    const isMobile = (window as any)?.os as string | undefined;
    if (isMobile && typeof isMobile !== 'undefined') {
      localStorage.setItem(STORAGE_ISMOBILE, isMobile);
    }
  }

  private saveExt() {
    const isExternal = (window as any)?.external as string | undefined;
    if (isExternal && typeof isExternal !== 'undefined') {
      localStorage.setItem(STORAGE_ISEXTERNAL, isExternal);
    }
  }
}
