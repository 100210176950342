import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnDestroy, OnInit, ViewChild, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { isBoolean } from 'lodash-es';
import { ArgumentOutOfRangeError, ReplaySubject, Subject } from 'rxjs';
import { shareReplay, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/app/auth/auth.service';
import {
  DataModalMessage,
  DataUsers,
  SearchAudify,
  AccessFilterCombo,
  PermissFilterCombo,
  GeographiesFilterCombo,
  DivisionFilterCombo,
  CodigoActivacionDTO,
  UserAudifyDTO
} from 'src/app/shared/models/audify';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { AESHelper } from '../helpers/aes';

import {
  CODE,
  INITIAL_USERS_COLUMNS,
} from '../mocks/mocksData.mock';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { UserDetailComponent } from '../user-detail/user-detail.component';
import { UserManagementService } from '../services/user-management.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserManagementComponent implements OnInit, OnDestroy {
  search: SearchAudify;
  filters: SearchAudify;
  types: AccessFilterCombo[] = [];
  permissions: PermissFilterCombo[] = [];
  geographies: GeographiesFilterCombo[] = [];
  divisions: DivisionFilterCombo[] = [];
  dataUsers: DataUsers[] = [];
  dataSource: MatTableDataSource<any>;
  results: boolean;
  columnDef: string[];
  userColumns: string[] = [];
  messageData: DataModalMessage;
  /** controla la introduccion de palabras para el autocompletado multiple */
  accessTypeMultiFilterCtrl: FormControl = new FormControl();
  permissionsMultiFilterCtrl: FormControl = new FormControl();
  geographyMultiFilterCtrl: FormControl = new FormControl();
  divisionsMultiFilterCtrl: FormControl = new FormControl();
  /** lista de unidades filtradas por la palabra */
  accessTypesFiltradosMulti: ReplaySubject<AccessFilterCombo[]> = new ReplaySubject<AccessFilterCombo[]>(1);
  permissionsFiltradosMulti: ReplaySubject<PermissFilterCombo[]> = new ReplaySubject<PermissFilterCombo[]>(1);
  geographiesFiltradosMulti: ReplaySubject<GeographiesFilterCombo[]> = new ReplaySubject<GeographiesFilterCombo[]>(1);
  divisionsFiltradosMulti: ReplaySubject<DivisionFilterCombo[]> = new ReplaySubject<DivisionFilterCombo[]>(1);

  permiOpt: string[];
  openModal: boolean;
  userDetail: any;
  usersFilterForm: FormGroup;

  // myCustomPaginatorIntl: CustomMatPaginatorIntl;

  /** Subject that emits when the component has been destroyed. */
  protected onDestroy = new Subject<void>();

  @ViewChild('selectionAccessTypeModel') multiSelectAccessType: MatSelect | undefined;
  @ViewChild('selectionPermissionsModel') multiSelectPermisssions: MatSelect | undefined;
  @ViewChild('selectionGeographyModel') multiSelectGeographies: MatSelect | undefined;
  @ViewChild('selectionDivisionModel') multiSelectDivisions: MatSelect | undefined;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private excelService: ExcelService,
    private authService: AuthService,
    public dialog: MatDialog,
    public aesHelper: AESHelper,
    public loadingBarService: LoadingBarService,
    private userManageService: UserManagementService,
    private _snackBar: MatSnackBar
  ) {
    this.dataSource = new MatTableDataSource<DataUsers>(this.dataUsers);
    this.columnDef = INITIAL_USERS_COLUMNS;
    this.results = true;
    this.permiOpt = ['I', 'R', 'P', 'M', 'A', 'C'];
    this.openModal = false;
    this.search = {
      nombre: null,
      tipoAcceso: null,
      permisos: null,
      geografia: null,
      division: null,
      usuariosBaja: null
    };
    this.filters = {
      nombre: null,
      tipoAcceso: null,
      permisos: null,
      geografia: null,
      division: null,
      usuariosBaja: null
    };
    this.messageData = {
      title: '',
      textBody: '',
    };
    this.usersFilterForm = new FormGroup({
      name: new FormControl(''),
      accessType: new FormControl(''),
      permissions: new FormControl(''),
      geography: new FormControl(''),
      division: new FormControl(''),
      includeUnsubscribeUsers: new FormControl(''),

    });
    this.loadCombos();
  }

  ngOnInit() {
    this.authService.refreshToken().subscribe();
    this.getFilters();
    this.userColumns = INITIAL_USERS_COLUMNS;
    // this.loadCombos();
    this.getUsers();

  }

  getFilters() {
    this.userManageService.getTipoAccesos().pipe(take(1)).subscribe(res => {
      this.types = res;
    });
    this.userManageService.getPermisos().pipe(take(1)).subscribe(res => {
      this.permissions = res;
    });
    this.userManageService.getGeografias().pipe(take(1)).subscribe(res => {
      this.geographies = res;
    });
  }

  getUsers() {

    this.sanitizeCombos();

    this.userManageService.getUsers(this.filters).pipe(take(1)).subscribe(res => {
      this.filterPermisosData(res);

      this.dataSource = new MatTableDataSource<DataUsers>(this.dataUsers);

      if (this.paginator){
        this.dataSource.paginator = this.paginator;
      }
    });

    this.formatCheckbox();
  }
  changeDataSource(event: any[]) {

    this.filterPermisosData(event)
    this.dataSource.data = this.dataUsers;
  }

  filterPermisosData(res: any) {
    const data = [...res];
    this.dataUsers = [];
    data.forEach(item => {
      if (item.permisos) {
        item.permisos = item.permisos.toString().split('');
        item.permisos = item.permisos.filter((char: string) => char === char.toUpperCase());
      }
      this.dataUsers.push(item);

    })
  }

  cleanForm(): void {
    this.initSearch();
    this.usersFilterForm.reset();
  }

  find(): void {
    this.getUsers();
  }

  searchUsers(event: any): void {
    this.getUsers();
    event.preventDefault();
    event.stopPropagation();

  }

  exportExcel(): void {
    this.userManageService.getcabecerasXLS().subscribe(cabeceras => {
      const aux: any[] = [];
      cabeceras.forEach((element: Object) => {
        aux.push(Object.values(element)[0]);
      });

      this.sanitizeCombos();
      this.userManageService.getUsersAudifyXLS(this.filters).subscribe(res => {
        res.forEach(item => {
          this.renameKeys(item, aux);
        });

        this.excelService.exportAsExcelFile(res, 'audify');
      });
      this.formatCheckbox();
    });
  }

  cambioPaginacion(event: PageEvent): void {

  }

  showUserDetail(row: any): void {
    this.userDetail = row;
    this.openModalUserDetail()
  }

  openModalUserDetail() {
    const modalRef = this.dialog.open(UserDetailComponent, {
      data: {
        userData: this.userDetail,
        autoFocus: false,
        restoreFocus: false,
        search: this.search
      }
    })
    modalRef.componentInstance.dataUser.subscribe((data: any) => {
      if (data) {
        this.changeDataSource(data);
      }
    })
  }

  closeModal(): void {
    this.openModal = false;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
  loadCombos() {
    this.accessTypeMultiFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.getCombosValues(
        this.types,
        this.usersFilterForm.value.accessType,
        this.accessTypesFiltradosMulti,
      );
    });
    this.permissionsMultiFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.getCombosValues(
        this.permissions,
        this.permissionsMultiFilterCtrl,
        this.permissionsFiltradosMulti,
      );
    });

    this.geographyMultiFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.getCombosValues(
        this.geographies,
        this.geographyMultiFilterCtrl,
        this.geographiesFiltradosMulti,
      );
    });
    this.divisionsMultiFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.getCombosValues(
        this.divisions,
        this.divisionsMultiFilterCtrl,
        this.divisionsFiltradosMulti,
      );
    });
  }

  generateActivationKey(): void {
    this.authService.getAccessToken().subscribe(token => {
      if (token) {
        this.userManageService.getPrimeraClave(token).subscribe((res: CodigoActivacionDTO) => {

          this.messageData = {
            title: this.translate.instant('AUDIFY.MODAL.activationKeyTitle'),
            textBody: this.translate.instant('AUDIFY.MODAL.activationKeyMessage'),
            buttons: [this.translate.instant('AUDIFY.MODAL.regenCode')],
            code: res.installCode,
          };
          this.dialog.open(ModalMessageComponent, { data: this.messageData });
        }, (err: any) => {
          this._snackBar.open(this.translate.instant('AUDIFY.USERMANAGEMENT.noPermission'));
        });
      }
    });
  }

  private initSearch(): void {
    this.search = {
      nombre: null,
      tipoAcceso: null,
      permisos: null,
      geografia: null,
      division: null,
      usuariosBaja: null
    };
    this.filters = this.search;
  }

  protected getCombosValues(list: any[], control: FormControl, field: ReplaySubject<any>) {
    if (!list) {
      return;
    }
    // get the search keyword
    let search = control.value;
    if (!search) {
      field.next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    field.next(list.filter(elem => elem.name.toLowerCase().indexOf(search) > -1));
  }

  cambioGeografia(detail: MatOptionSelectionChange): void {
    const geoValue = detail.source.value;
    if (!geoValue) {
      this.divisions = [];
      this.search.division = null;
    } else {
      this.userManageService.getDivisiones(geoValue).pipe(take(1)).subscribe(res => {
        this.divisions = res;
      });
    }
  }

  sanitizeCombos(): void {
    if(this.search.nombre != null){
      this.filters.nombre = this.search.nombre;
    }
    if (this.search.permisos) {
      if (Array.isArray(this.search.permisos)) {
        this.filters.permisos = this.search.permisos.join(',').toUpperCase();
      }
    }

    if (this.search.geografia) {
      if (Array.isArray(this.search.geografia)) {
        this.filters.geografia = this.search.geografia.join(',');
      }
    }

    if (this.search.tipoAcceso) {
      if (Array.isArray(this.search.tipoAcceso)) {
        this.filters.tipoAcceso = this.search.tipoAcceso.join(',');
      }
    }

    if (this.search.division) {
      if (Array.isArray(this.search.division)) {
        this.filters.division = this.search.division.join(',');
      }
    }

    if (this.search.usuariosBaja && isBoolean(this.search.usuariosBaja)) {
      this.search.usuariosBaja = 'S';
      this.filters.usuariosBaja = 'S';
    } else {
      this.search.usuariosBaja = 'N';
      this.filters.usuariosBaja = 'N';
    }
  }

  formatCheckbox(): void {
    if (this.search.usuariosBaja == 'S') {
      this.search.usuariosBaja = true;
    } else {
      this.search.usuariosBaja = false;
    }
  }

  renameKeys(item: any[], new_keys: any[]) {
    let i = 0;
    Object.keys(item).forEach((element: any) => {
      item[new_keys[i]] = item[element];
      delete item[element];
      i++;
    });
  }
}
