import { Fichero } from '../models/entities/fichero';
import { STORAGE_ISMOBILE, STORAGE_ISEXTERNAL } from '../constants/storage-keys';

const extension = {
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'application/pdf': '.pdf',
} as Record<string, string>;

export function downloadFile(file: Fichero) {
  const filename = `${file.name}${extension[file.type]}`;
  const blob = b64toBlob(file.data, file.type);

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }

  const a = document.createElement('a');
  if (isMobile()) {
    (window as any).webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({ action: 'download', data: 'data:' + file.type + ';base64,' + file.data }));
  } else {
    a.setAttribute('style', 'display: none');
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    a.rel = 'noopener';
  }
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(a.href);
  }, 100);
}

export function getFile(file: Fichero) {
  const filename = `${file.name}${extension[file.type]}`;
  const blob = b64toBlob(file.data, file.type);

  return [blob, filename] as const;
}

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function isMobile() {
  let isMobileValue = localStorage.getItem(STORAGE_ISMOBILE);
  if (typeof isMobileValue !== 'undefined' &&
    isMobileValue !== '') {
    const isMobile = (window as any)?.os as string | undefined;
    if (isMobile && typeof isMobile !== 'undefined') {
      localStorage.setItem(STORAGE_ISMOBILE, isMobile);
      isMobileValue = isMobile;
    }
  }
  if (
    typeof isMobileValue !== 'undefined' &&
    isMobileValue !== '' &&
    (isMobileValue === 'ios' || isMobileValue === 'android')
  ) {
    return true;
  }
  return false;
}

export function isExternal() {
  let isExternalValue = localStorage.getItem(STORAGE_ISEXTERNAL);
  if (typeof isExternalValue !== 'undefined' &&
    isExternalValue !== '') {
    const isExternal = (window as any)?.external as string | undefined;
    if (isExternal && typeof isExternal !== 'undefined') {
      localStorage.setItem(STORAGE_ISEXTERNAL, isExternal);
      isExternalValue = isExternal;
    }
  }
  if (
    typeof isExternalValue !== 'undefined' &&
    isExternalValue !== '' &&
    (isExternalValue === 'true')
  ) {
    return true;
  }
  return false;
} 
