import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

export function closeFilterWhenClickBackBtn(
  isMobile$: Observable<boolean>,
  isSidenavOpened$: BehaviorSubject<boolean>,
  destroy$: Subject<unknown>,
  callback: () => void,
) {
  fromEvent(window, 'popstate')
    .pipe(
      withLatestFrom(isMobile$, isSidenavOpened$),
      filter(([, isMobile, isSidenavOpened]) => isMobile && isSidenavOpened),
      takeUntil(destroy$),
    )
    .subscribe(() => {
      callback();
      history.go(1);
    });
}

export function cancelLoginRedirectionWhenClickBackBtn(
  isMobile$: Observable<boolean>,
  destroy$: Subject<unknown>,
) {
  fromEvent(window, 'popstate')
    .pipe(
      withLatestFrom(isMobile$),
      filter(([, isMobile]) => isMobile),
      takeUntil(destroy$),
    )
    .subscribe(() => {
      if (location.href.includes('logcorp')) {
        history.go(1);
      }
    });
}
