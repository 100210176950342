import { Inject, Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from '../../app/api/api.service';
import { Division } from '../../../models/entities/division';
import { LOGOUT } from '../../../constants/global-tokens';

@Injectable({
  providedIn: 'root',
})
export class DivisionStateService implements OnDestroy {
  // tslint:disable-next-line:variable-name
  private _userDivisions$ = new BehaviorSubject<Division[]>([]);
  userDivisions$ = this._userDivisions$.asObservable();

  private readonly baseUrl = '/api/divisions';
  private destroy$ = new Subject();

  constructor(private api: ApiService, @Inject(LOGOUT) private logout$: Observable<void>) {
    this.logout$.pipe(takeUntil(this.destroy$)).subscribe(() => this.reset());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  loadUserDivisions() {
    return this.api
      .get<Division[]>(`${this.baseUrl}/getUserDivisions`)
      .subscribe(divisions => this._userDivisions$.next(divisions));
  }

  reset() {
    this._userDivisions$.next([]);
  }
}
