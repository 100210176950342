import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router } from '@angular/router';

import { defaultsDeep } from 'lodash-es';
import { filter, map } from 'rxjs/operators';

export function getNavigationEndEvent(router: Router) {
  return router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(event => event as NavigationEnd),
  );
}

export function getDefaultNavigationExtras(extras?: NavigationExtras): NavigationExtras {
  return defaultsDeep({
    ...extras,
    queryParamsHandling: 'merge',
    queryParams: { lang: undefined, ...extras?.queryParams, token: undefined },
  });
}

export function getSelectedArea(route: ActivatedRoute) {
  return route.queryParams.pipe(map(({ area }) => Number(area) || 0));
}

export function getSelectedGeographies(route: ActivatedRoute) {
  return route.queryParams.pipe(map(({ geographies }) => geographies || '0'));
}

export function getSelectedAreaAndGeographies(route: ActivatedRoute) {
  return route.queryParams.pipe(
    map(
      params => ({
          ...params,
          area: Number(params.area) || 0,
          geographies: (params.geographies || '0') as string,
        } as { area: number; geographies: string } & Params),
    ),
  );
} 