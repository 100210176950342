import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/app/auth/auth.service';
import { isExternal } from '../utils/files';

const AUTHORIZATION_HEADER = 'Authorization';
const AUTHORIZATION_HEADER_BIS = 'X-IDAudify';
const AUTHORIZATION_EXTERNAL = 'IDExterno';
const UNAUTHORIZED_ERROR_STATUS = 401;

@Injectable()
export class AuthInterceptor {
  semaforo = false;
  private redirect = false;

  constructor(private authService: AuthService, private router: Router, private http: HttpClient) {
    // Llamada al fichero de configuración
    this.authService.getConfig();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.redirect && !this.authService.semaforo) {
      this.authService.semaforo = true;
      // return without access token
      return next.handle(request);
    }
    return this.authService.getAccessToken().pipe(
      map(token => (!!token ? this.addAccessToken(token, request) : request.clone())),
      switchMap(req => next.handle(req)),
      catchError((res: HttpErrorResponse) => this.responseError(res)),
    );
  }

  private addAccessToken(token: string, request: HttpRequest<unknown>) {
    let url = request.url;
    let valor = 'interno';
    // Se envian ambos tokens al back
    if (isExternal()) {
      url = url.replace('/api/', '/api-ext/');
      valor = 'externo';
    }
    return request.clone({
      setHeaders: {
        [AUTHORIZATION_HEADER]: `Bearer ${token}`,
        [AUTHORIZATION_HEADER_BIS]: `Bearer ${token}`,
        [AUTHORIZATION_EXTERNAL]: `${valor}`,
      },
      url,
    });
  }

  private responseError(response: HttpErrorResponse) {
    if (response.status === UNAUTHORIZED_ERROR_STATUS) {
      this.authService.logout();
      this.navigateToError();
    }

    return throwError(response);
  }

  private navigateToError() {
    this.router.navigate(['/notAllowed'], {
      skipLocationChange: true, // minimal effect. see https://github.com/angular/angular/issues/17004
      queryParams: {
        url: this.router.url,
      },
    });
  }
}
