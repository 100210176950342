<div class="message-dialog">
  <div class="message-dialog__header">
    <h1 class="message-dialog__header__title">
      {{ title }}
    </h1>
  </div>

  <p class="message-dialog__body" [innerHTML]="message | sanitizeHtml"></p>

  <footer class="message-dialog__footer flex-column flex-sm-row">
    <button mat-stroked-button *ngIf="type === types.Confirm" class="santander-stroked-btn" (click)="onClick('cancel')">
      {{ 'BUTTON_ACTIONS.CANCEL'| translate }}
    </button>
    <button mat-raised-button *ngIf="type === types.Confirm" class="santander-raised-btn me-sm-3 mt-2 mt-sm-0" color="primary" (click)="onClick('accept')">
    {{ 'BUTTON_ACTIONS.RETRY'| translate }}
    </button>
    <button mat-raised-button *ngIf="type !== types.Confirm" class="santander-raised-btn mt-2 mt-sm-0" color="primary" (click)="onClick('accept')">
    {{ 'BUTTON_ACTIONS.OK'| translate }}
    </button>
  </footer>
</div>