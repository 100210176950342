import { Inject, Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Geography } from '../../../models/entities/geography';
import { GeographyService } from '../geography.service';
import { LOGOUT } from '../../../constants/global-tokens';

@Injectable({
  providedIn: 'root',
})
export class GeographyStateService implements OnDestroy {
  // tslint:disable-next-line:variable-name
  private _geography$ = new BehaviorSubject<Geography[]>([]);
  geographies$ = this._geography$.asObservable();

  private destroy$ = new Subject();

  constructor(
    private geographyService: GeographyService,
    @Inject(LOGOUT) private logout$: Observable<void>,
  ) {
    this.logout$.pipe(takeUntil(this.destroy$)).subscribe(() => this.reset());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  loadGeographies({ divisionId }: { divisionId: number }) {
    this.geographyService.getNestedGeographies(divisionId).subscribe(result => {
      const geographies = Object.values(result);
      this._geography$.next(geographies);
    });
  }

  reset() {
    this._geography$.next([]);
  }

  sortMap(geographies: Geography[]): void {


  }
}
