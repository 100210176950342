import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { DialogService } from './services/dialog.service';
import { MessageDialogComponent } from './components/dialogs';
import { SanitizeHtmlPipe } from './pipes/sanitize-html/sanitize-html.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SanitizeHtmlPipe, MessageDialogComponent],
  exports: [],
  imports: [CommonModule, MatButtonModule, MatDialogModule, TranslateModule],
  providers: [DialogService],
})
export class DialogModule {}
