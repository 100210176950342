import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';

import { BottomMenuMobileComponent } from './components/bottom-menu-mobile/bottom-menu-mobile.component';
import { SpinnerComponent } from './components/spinner/spinner.component';

const materialModules = [
  MatButtonModule,
  MatButtonToggleModule,
  MatGridListModule,
  MatCardModule,
  MatDatepickerModule,
  MatDividerModule,
  MatFormFieldModule,
  MatListModule,
  MatMomentDateModule,
  MatSelectModule,
  MatMenuModule,
  MatBadgeModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatIconModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatTableModule,
  MatInputModule,
  MatSlideToggleModule,
  MatDialogModule,
];

const modules = [ChartsModule, TranslateModule];

const components = [BottomMenuMobileComponent, SpinnerComponent];

@NgModule({
  declarations: [...components],
  exports: [CommonModule, ...materialModules, ...modules, ...components],
  imports: [CommonModule, ...materialModules, TranslateModule],
})
export class SharedModule {}
