import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ProblemDetails, ValidationError } from '../../../http/validation-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  // tslint:disable-next-line:variable-name
  private _errors$ = new Subject<ProblemDetails | ValidationError | Error | HttpErrorResponse>();

  errors$ = this._errors$.asObservable();

  next(error: ProblemDetails | ValidationError | Error | HttpErrorResponse) {
    this._errors$.next(error);
  }
}
