import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';

import { ModalMessageComponent } from './modal-message.component';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule],
  declarations: [ModalMessageComponent],
  exports: [ModalMessageComponent],
})
export class ModalMessageModule {}
