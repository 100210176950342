import { isNil } from 'lodash-es';
import * as moment from 'moment';
import { Auditoria } from 'src/app/core/models/entities/auditoria';

import { DetalleAuditoria } from '../core/models/entities/detalle-auditoria';
const iconUnit = 'sys-037-tick-in-a-circle.svg';
const iconUnitEmpty = 'circle-svgrepo.svg';
export default class Utils {
  ratingToStyle(rating: string): string {
    let oval: string;
    switch (rating) {
      case 'Bueno':
        oval = 'oval-bueno';
        break;
      case 'Aceptable':
        oval = 'oval-aceptable';
        break;
      case 'Mejorable':
        oval = 'oval-mejorable';
        break;
      case 'No Satisfactorio':
        oval = 'oval-nosatisfactorio';
        break;
      default:
        oval = 'oval-nr';
        break;
    }
    return oval;
  }

  diaEmision(fecha: string): string {
    return fecha.split('/')[0];
  }

  mesEmision(fecha: string, locale = 'es'): string {
    return moment(fecha, 'DD/MM/YYYY').locale(locale).format('MMMM');
  }

  // format DD/MM/YY
  dateToShortString(fecha: string): string {
    return moment(fecha).locale('es').format('DD/MM/YY');
  }

  //#region render classes
  topArrow(top: string): string {
    return top === 'S' ? iconUnit : 'func-031-error.svg';
  }

  evolIcon(evol: string): string {
    let icon = '';
    switch (evol) {
      case 'N': // nuevo
        icon = 'evol-new.svg';
        break;
      case 'E': // baja
        icon = 'evol-bajo.svg';
        break;
      case 'I': // estable
        icon = 'evol-igual.svg';
        break;
      case 'M': // sube
        icon = 'evol-alto.svg';
        break;
      case 'SR': // sube
        icon = iconUnitEmpty;
        break;
      default:
        // SR: sinrating
        icon = iconUnit;
        break;
    }
    return icon;
  }

  unidadNueva(isNueva: string): string {
    return isNueva === 'S' ? iconUnit : 'func-031-error.svg';
  }
  //#endregion

  contains(array: string[], s: string): boolean {
    return array.indexOf(s) !== -1;
  }

  unullify(s: string): string {
    if (isNil(s)) {
      return '-';
    }
    return s;
  }

  hourFromDate(fecha: Date) {
    return moment(fecha).locale('es').format('hh:mm') + ' h';
  }

  hasAuditDetails(detalleAuditoria: DetalleAuditoria, auditoriasAnteriores: Auditoria[]): boolean {
    return (
      (!isNil(detalleAuditoria.txDistribucion) && detalleAuditoria.txDistribucion.trim() !== '') ||
      (!isNil(detalleAuditoria.txResumen) && detalleAuditoria.txResumen.trim() !== '') ||
      (!isNil(detalleAuditoria.nurai) && detalleAuditoria.nurai > 0) ||
      (!isNil(detalleAuditoria.nurap) && detalleAuditoria.nurap > 0) ||
      (!isNil(auditoriasAnteriores) && auditoriasAnteriores.length > 0)
    );
  }

  getOvalClass(item: string): string {
    switch(item) {
      case 'RAI':
        return 'oval oval-rai';
      case 'RAP':
        return'oval oval-rap';
      case 'IeR':
        return'oval oval-ier';  
      case 'IuR':
        return'oval oval-iur';
      default:
        return 'oval';
    }
  }

  checkIfObjectsDifferent(object1: any, object2:any): boolean {
    const object1Entires = Object.entries(object1);
    const object2Entires = Object.entries(object2);
    return !object1Entires.map((params, i) => (params[0] !== object2Entires[i][0] || params[1] !== object2Entires[i][1]) ? false : true
    ).includes(false)
  }
}
