import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { DialogModule } from '../dialog/dialog.module';

import { FilterModalMobileComponent } from './full-layout/header/filter-modal-mobile/filter-modal-mobile.component';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { HeaderComponent } from './full-layout/header/header.component';
import { SidebarComponent } from './full-layout/sidebar/sidebar.component';

const materialModules = [
  MatSidenavModule,
  MatToolbarModule,
  MatTooltipModule,
  MatIconModule,
  MatFormFieldModule,
  MatSelectModule,
  MatListModule,
  MatButtonModule,
  MatCardModule,
  MatMenuModule,
  MatBadgeModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatRadioModule,
  MatInputModule,
];

@NgModule({
  declarations: [
    FullLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FilterModalMobileComponent,
  ],
  exports: [FullLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    ...materialModules,
    DialogModule,
    FormsModule,
    TranslateModule,
  ],
})
export class LayoutsModule {
  static forRoot(): ModuleWithProviders<LayoutsModule> {
    return {
      ngModule: LayoutsModule,
    };
  }
}

export { FullLayoutComponent };
