import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, of } from 'rxjs';
import { NuevoInforme } from 'src/app/core/models/entities/nuevo-informe';
import { IndicadoresRecomendacionesComponent } from 'src/app/dashboard/home/recomendaciones-vivas/indicadores-recomendaciones/indicadores-recomendaciones.component';

import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class NotificationService {
  private baseUrl = '/api/nuevosInformes/getNuevosInformes';
  userConfig$ = this.authService.userConfig$;
  constructor(private snackBar: MatSnackBar, private api: ApiService, private authService: AuthService) {
    this.authService.getConfig();
  }

  showMessage(message: string): void {
    this.snackBar.open(message, undefined, {
      duration: 5000,
    });
  }

  getLanguage(): string{
    let idioma = "ESP";
    this.userConfig$.subscribe(res =>{
      if(res){
      idioma = res.idioma;
      }
    });
    return idioma;
  }


  getNotifications(): Observable<NuevoInforme[]> {
     return this.api.get<NuevoInforme[]>(`${this.baseUrl}`);

    // Mocks
    // const notifications: NuevoInforme[] = [
    //   { rgTrabajo: 5012, feDistribucion: '2020-11-04T15:00:44.215Z', feDistribucionString: '04/11/2020', nbTrabajo: 'Lorem ipsum dolor sit amet', swResaltar: 'S' },
    //   { rgTrabajo: 4012, feDistribucion: '2020-11-04T14:00:44.215Z', feDistribucionString: '04/11/2020', nbTrabajo: 'Lorem ipsum dolor sit amet', swResaltar: 'N' },
    //   { rgTrabajo: 4012, feDistribucion: '2020-11-04T14:00:44.215Z', feDistribucionString: '04/11/2020', nbTrabajo: 'Lorem ipsum dolor sit amet', swResaltar: 'N' },
    //   { rgTrabajo: 4012, feDistribucion: '2020-11-04T14:00:44.215Z', feDistribucionString: '04/11/2020', nbTrabajo: 'Lorem ipsum dolor sit amet', swResaltar: 'N' },
    //   { rgTrabajo: 3012, feDistribucion: '2020-11-02T11:00:44.215Z', feDistribucionString: '02/11/2020', nbTrabajo: 'Lorem ipsum dolor sit amet', swResaltar: 'S' },
    // ];

    // return of(notifications);

  }
}
