<div class="modal-message" *ngIf="data">
  <div class="modal-message__head">
    <h2 mat-dialog-title class="modal-message__title" [innerHTML]="data.title"></h2>
    <button
      mat-flat-button
      *ngIf="!data.input"
      mat-dialog-close
      style="
        background-color: #ec0000;
        color: white;
        min-width: 36px;
        height: 36px;
        padding: 0;
        position: relative;
        top: -24px;
        right: -24px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      "
    >
      <mat-icon class="material-icons close-icon">close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content class="modal-message__text">
    <p [innerHTML]="data.textBody"></p>
    <span *ngIf="data.code" [innerHTML]="data.code"></span>
    <div *ngIf="data.input" class="temp_pass">
      <mat-form-field>
        <input matInput [(ngModel)]="data.codpas" [ngModelOptions]="{standalone: true}" required minlength="12">
      </mat-form-field>
    </div>
  </div>
  <div
    mat-dialog-actions
    class="modal-message__actions-btns"
    *ngIf="data.buttons && data.buttons.length > 0 && data.buttons.length > 1"
  >
    <button mat-button [mat-dialog-close]="data.codpas" class="btn-color-red" [innerHTML]="data.buttons[0]"></button>
    <button
      mat-button
      mat-dialog-close
      class="btn-color-white"
      [innerHTML]="data.buttons[1]"
    ></button>
  </div>
  <div
    mat-dialog-actions
    class="modal-message__actions-btn"
    *ngIf="data.buttons && data.buttons.length === 1"
  >
    <button mat-button class="btn-color-red" (click)="regenerateKey()" [innerHTML]="data.buttons[0]"></button>
  </div>
</div>
