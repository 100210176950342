<h1 class="terms__title">{{ 'DASHBOARD.TERMS_LABEL_TITLE' | translate }}</h1>

<div [innerHTML]="body"></div>

<div class="d-flex align-items-center">
  <mat-checkbox color="primary" (change)="hasAccept = $event.checked"></mat-checkbox>
  <label class="ms-1 terms__text">{{ 'DASHBOARD.TERMS_LABEL_CHECK' | translate }}</label>
</div>

<div class="d-flex mt-3">
  <div class="flex-fill"></div>
  <button class="santander-raised-btn" color="primary" mat-raised-button [disabled]="!hasAccept" (click)="onClick()">
    {{ 'BUTTON_ACTIONS.OK' | translate }}
  </button>
</div>