import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  clearAll() {
    localStorage.clear();
  }

  get(key: string, base64?: boolean): Observable<string | undefined>;
  get<T>(key: string, base64?: boolean): Observable<T | undefined>;
  get<T>(key: string, base64 = false): Observable<T | string | undefined> {
    let value = localStorage.getItem(key);
    if (base64 && value) {
      value = atob(value);
    }

    try {
      value = value ? JSON.parse(value) : undefined;
    } catch (e) {
      // Cast error
    }

    return of(value || undefined);
  }

  set<T>(key: string, value: T, base64 = false) {
    const stringifyValue = JSON.stringify(value);
    localStorage.setItem(key, base64 ? btoa(stringifyValue) : stringifyValue);
  }
}
