<app-header
  #headerComponent
  [handset]="isHandset$ | async"
  [selectedUserDivision]="selectedUserDivision$ | async"
  [selectedGeographiesCodes]="selectedGeographies$ | async"
  [sidenavOpened]="drawer.opened"
  [geographies]="geographies$ | async"
  [userDivisions]="userDivisions$ | async"
  (toggleSidenav)="expandClass(); sidebarComponent.closeNotificationPanel(); drawer.toggle()"
></app-header>

<mat-sidenav-container
  [ngClass]="expand ? 'sidenav-container mat-drawer-container-expand' : 'sidenav-container'"
  (backdropClick)="headerComponent.toggleNotification()"
>
  <mat-sidenav
    style="overflow: initial !important"
    #drawer
    class="sidenav-container__menu"
    mode="over"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    (openedChange)="isSidenavOpened$.next($event)"
  >
    <app-sidebar
      #sidebarComponent
      [staticReport]="staticReport$ | async"
      (downloadDocument)="downloadDocument($event)"
      (downloadReport)="downloadReport($event)"
      (toggleSidenav)="expandClass(); sidebarComponent.closeNotificationPanel(); drawer.toggle()"
      (notifyOpenedNotifications)="headerComponent.updateNotificationBadge()"
    ></app-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-container__content">
    <div class="app-container">
      <div class="app-container__inner">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
