import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable, of } from 'rxjs';
import { filter, mapTo, take, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from '../services/app/auth/auth.service';
import { DialogService } from '../../dialog/services/dialog.service';
import { TermsModalComponent } from '../components/terms-modal/terms-modal.component';

@Injectable({
  providedIn: 'root',
})
export class TermsGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (/terms=true/.test(this.document.cookie)) {
      return of(true);
    }

    this.authService.userConfig$
      .pipe(
        filter(config => !!config),
        take(1),
      )
      .subscribe(config => {
        if (config?.swAcceso === 'C') {
          const dialogRef = this.dialogService.showCustomDialog(TermsModalComponent, {
            config: { maxHeight: '80vh' },
            disableClose: true,
          });
          return dialogRef.afterClosed().pipe(
            tap(() => {
              this.document.cookie = 'terms=true';
            }),
            mapTo(true),
            take(1),
          );
        }
      });

    return of(true);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
