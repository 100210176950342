import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';
import { InformeEstatico } from 'src/app/core/models/entities/InformeEstatico';
import { NotificationService } from 'src/app/core/services/app/notification/notification.service';
import { InformeEstaticoService } from 'src/app/core/services/entities/informe-estatico.service';
import { LanguageService } from 'src/app/core/services/entities/language.service';
import { onLangChange } from 'src/app/core/utils/i18n';
import { closeFilterWhenClickBackBtn } from 'src/app/core/utils/rx-back-btn-events';

import { Division } from '../../core/models/entities/division';
import { DivisionStateService } from '../../core/services/entities/with-state/division-state.service';
import { Geography } from '../../core/models/entities/geography';
import { GeographyStateService } from '../../core/services/entities/with-state/geography-state.service';
import { getSelectedArea, getSelectedGeographies } from '../../core/utils/router';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@Component({
  selector: 'app-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullLayoutComponent implements OnInit, OnDestroy {
  isMobile$: Observable<boolean>;
  isSidenavOpened$ = new BehaviorSubject(false);
  staticReport$: Observable<InformeEstatico[]>;
  userDivisions$: Observable<Division[]>;
  geographies$: Observable<Geography[]>;
  selectedUserDivision$: Observable<number>;
  selectedGeographies$: Observable<string>;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay(),
  );

  url: string | undefined;

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild('headerComponent')
  headerComponent!: HeaderComponent;
  @ViewChild('sidebarComponent')
  sidebarComponent!: SidebarComponent;
  @ViewChild('drawer')
  drawer!: MatSidenav;

  private destroy$ = new Subject();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private divisionStateService: DivisionStateService,
    private geographyStateService: GeographyStateService,
    private informeEstaticoService: InformeEstaticoService,
    private languageService: LanguageService,
    route: ActivatedRoute,
    private translate: TranslateService,
    private notification: NotificationService,
  ) {
    this.staticReport$ = this.informeEstaticoService.getAllInformeEstatico();
    this.userDivisions$ = this.divisionStateService.userDivisions$;
    this.geographies$ = this.geographyStateService.geographies$;
    this.selectedUserDivision$ = getSelectedArea(route);
    this.selectedGeographies$ = getSelectedGeographies(route);
    this.isMobile$ = this.breakpointObserver.observe('(max-width: 575.9998px)').pipe(
      map(result => result.matches),
      shareReplay(),
    );
  }

  expand = false;

  ngOnInit() {
    onLangChange(this.translate, this.destroy$, this.languageService.loading).subscribe(() => {
      this.divisionStateService.loadUserDivisions();
    });
    this.selectedUserDivision$.pipe(
      distinctUntilChanged(),
    ).subscribe(divisionId => {
      this.geographyStateService.loadGeographies({ divisionId });
    });
    closeFilterWhenClickBackBtn(this.isMobile$, this.isSidenavOpened$, this.destroy$, () => {
      this.expand = false;
      this.drawer.close(),
        this.sidebarComponent.closeNotificationPanel(),
        this.headerComponent.showNotification();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  downloadDocument(rgInforme: number) {
    this.informeEstaticoService.getPdf(rgInforme).subscribe(
      result => {
        this.notification.showMessage(this.translate.instant('NOTIFICATIONS.PDF_SUCCESS'));
      },
      error => {
        this.notification.showMessage(this.translate.instant('NOTIFICATIONS.PDF_SUCCESS'));
      },
      () => {
        // 'onCompleted' callback.
      },
    );
  }

  // notetoself: move
  downloadReport(rgTrabajo: number) {
    this.informeEstaticoService.getReport(rgTrabajo).subscribe(
      result => {
        this.notification.showMessage(this.translate.instant('NOTIFICATIONS.PDF_SUCCESS'));
      },
      error => {
        this.notification.showMessage(this.translate.instant('NOTIFICATIONS.PDF_ERROR'));
      },
    );
  }

  expandClass() {
    this.expand = !this.expand;
  }
}
