<mat-toolbar class="app-container header">
  <div class="app-container__inner header__container">
    <button mat-icon-button class="menu-button" aria-label="menu button" (click)="toggleSidenav.emit(); toggleNotification()">
      <span
        *ngIf="show && !isOpenedNotifications && notifications?.length > 0"
        class="sidebar__badge notif-home"
        >{{ notifications?.length }}</span
      >
      <mat-icon class="white-icon">menu</mat-icon>
    </button>

    <!-- Desktop -->
    <div class="d-none d-sm-flex align-items-center w-100">
      <!-- Santander logo -->
      <!-- <a class="header__logo" routerLink="/home" (click)="sidenavOpened && toggleSidenav.emit()"
        ><img src="assets/cabecera.png" width="30" height="40"
      /></a> -->

      <a class="header__title text-decoration-none ms-3" routerLink="/home" (click)="sidenavOpened && toggleSidenav.emit()"><img src="assets/logo_white.png" width="30" height="40" /> Santander | Audify</a>

      <span class="flex-spacer"></span>

      <button class="header__button header__button--geography" [class.header__button--active]="isOpenGeographyMenu" [matMenuTriggerFor]="geographyMenu" (menuOpened)="setIsOpenGeographyMenu(true)" (menuClosed)="setIsOpenGeographyMenu(false)">
        <img src="assets/func-146-access-to-web.png" width="32" height="32" />
        <div class="header__button-text">
          <p class="m-0">{{ 'DASHBOARD.GEOGRAPHY_LABEL' | translate }}</p>
          <p
            class="m-0 header__button-selected-item text-truncate"
            matTooltip="{{ selectedGeographiesNames || 'DASHBOARD.SELECTION_LABEL' | translate }}"
          >
            <strong>{{
              selectedGeographiesNames || 'DASHBOARD.SELECTION_LABEL' | translate
            }}</strong>
          </p>
        </div>
        <div class="header__button-arrow"></div>
      </button>

      <mat-menu #geographyMenu="matMenu" class="header__menu">
        <div class="header__geography-menu" (click)="$event.stopPropagation()">
          <div *ngFor="let geography of geographiesModel; let index = index">
            <div class="header__geography-menu-checkbox pb-sm-1">
              <mat-checkbox class="santander-checkbox" color="primary" matTooltip="{{ geography.nbGeografia }}" [checked]="geography.allComplete" [indeterminate]="someComplete(geography)" (change)="setAll(geography, $event.checked)">
                <span class="ellipsis-mat-radio-label">
                  {{ geography.nbGeografia }}
                </span>
              </mat-checkbox>

              <div *ngIf="geography.subGeografias?.length" class="header__geography-menu-arrow" [class.header__geography-menu-arrow--expanded]="!geography.collapsed" (click)="geography.collapsed = !geography.collapsed"></div>
            </div>
            <span *ngIf="!geography.collapsed">
              <ul class="header__geography-menu-list text-truncate">
                <li *ngFor="let subGeography of geography.subGeografias">
                  <mat-checkbox
                    class="santander-checkbox"
                    color="primary"
                    matTooltip="{{ subGeography.nbGeografia }}"
                    [checked]="subGeography.checked"
                    (change)="subGeography.checked = $event.checked; updateAllComplete(geography)"
                  >
                    <span class="ellipsis-mat-radio-label">
                      {{ subGeography.nbGeografia }}
                    </span>
            </mat-checkbox>
            </li>
            </ul>
            </span>
          </div>
        </div>
        <div class="header__geography-menu-buttons pb-3">
          <button class="santander-stroked-btn" color="primary" mat-stroked-button (click)="cleanAllGeographies(); updateButton()">
            {{ 'BUTTON_ACTIONS.CLEAN' | translate }}
          </button>
          <button class="santander-raised-btn ms-1" color="primary" mat-raised-button button (click)="onChangeGeographies()">
            {{ 'BUTTON_ACTIONS.OK' | translate }}
          </button>
        </div>
      </mat-menu>

      <button class="header__button header__button--area" [class.header__button--active]="isOpenAreaMenu" [matMenuTriggerFor]="areaMenu" (menuOpened)="isOpenAreaMenu = true" (menuClosed)="isOpenAreaMenu = false">
        <img src="assets/serv-006-building.svg" width="32" height="32" />
        <div class="header__button-text">
          <p class="m-0">Areas</p>
          <p class="m-0 header__button-selected-item text-truncate">
            <strong>{{
              selectedUserDivisionName || 'DASHBOARD.SELECTION_LABEL' | translate
            }}</strong>
          </p>
        </div>
        <div class="header__button-arrow"></div>
      </button>

      <mat-menu #areaMenu="matMenu" class="header__menu">
        <mat-radio-group class="header__area-menu" [value]="selectedUserDivision" (click)="$event.stopPropagation()">
          <ul>
            <li *ngFor="let area of userDivisions; let last = last">
              <mat-radio-button color="primary" [class.mb-2]="!last" [value]="area.cdDivision" (click)="onChangeArea(area)" matTooltip="{{ area.nbDivision }}">
                <span class="ellipsis-mat-radio-label">
                  {{ area.nbDivision }}
                </span></mat-radio-button>
            </li>
          </ul>
        </mat-radio-group>
        <div class="header__geography-menu-buttons pb-3">
          <button class="santander-stroked-btn" color="primary" mat-stroked-button (click)="cleanAllAreas()">
            {{ 'BUTTON_ACTIONS.CLEAN' | translate }}
          </button>
        </div>
      </mat-menu>

      <button #searchMenuTrigger="matMenuTrigger" mat-icon-button class="example-icon" aria-label="icon-button with a search icon" style="margin-left: 20px" [matMenuTriggerFor]="searchMenu">
        <mat-icon svgIcon="func-071-search" [style.width.px]="32" [style.height.px]="32"></mat-icon>
      </button>

      <mat-menu #searchMenu="matMenu" class="header__search-menu mat-menu-panel--100vw">
        <form class="header__search-menu-container" (click)="$event.stopPropagation()" (ngSubmit)="
            onSearchClick(matSelect.value, searchInput.value); searchMenuTrigger.closeMenu()
          ">
          <mat-form-field appearance="fill" class="header__search-menu-form-field search-options">
            <mat-select #matSelect value="/trabajopage">
              <mat-option value="/trabajopage">{{ 'HOME.JOBS_AND_RATINGS_LABEL' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="mt-3 mt-sm-0 me-sm-3 header__search-menu-form-field search-text">
            <input #searchInput matInput placeholder="{{ 'HOME.BUSCAR' | translate }}" type="text" />
          </mat-form-field>

          <button class="mt-3 mt-sm-0 me-sm-4 search-button" color="primary" mat-mini-fab>
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </form>
      </mat-menu>
    </div>

    <!-- Mobile   -->
    <div class="d-flex d-sm-none align-items-center w-100">
      <span class="flex-spacer"></span>

      <span class="header__logo" routerLink="/home" (click)="sidenavOpened && toggleSidenav.emit()"><img src="assets/ban-005-bank-branch.svg" width="26" height="26"
      /></span>

      <div class="header__title" routerLink="/home" (click)="sidenavOpened && toggleSidenav.emit()">
        Audify
      </div>

      <span class="flex-spacer"> </span>
      <button #searchMenuTrigger="matMenuTrigger" mat-icon-button class="example-icon" aria-label="icon-button with a search icon" style="margin-left: 20px" [matMenuTriggerFor]="searchMenu">
        <mat-icon svgIcon="func-071-search" [style.width.px]="32" [style.height.px]="32"></mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>

<!-- Mobile -->
<div *ngIf="!sidenavOpened" class="d-sm-none toolbar-selector" (click)="openFilterModal()">
  <div class="toolbar-selector__container">
    <p class="m-0 text-truncate">
      <strong>{{ selectedGeographiesNames || 'DASHBOARD.GEOGRAPHY_LABEL' | translate }}</strong>
    </p>
  </div>

  <div class="toolbar-selector__divider"></div>

  <div class="toolbar-selector__container">
    <p class="m-0 text-truncate">
      <strong>{{ selectedUserDivisionName || 'DASHBOARD.AREA_LABEL' | translate }}</strong>
    </p>
  </div>

  <div class="toolbar-selector__arrow"></div>
</div>