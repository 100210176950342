export const INITIAL_USERS_COLUMNS: string[] = [
  'userId',
  'userName',
  'userType',
  'ambitoConsulta',
  'ultimoAcceso',
  'vip',
  'permisos',
  'notificaciones',
];
const ariesVisibility = {
  administracion : 'COR-Administración',
  bancaComercial : 'COR-Banca comercial'

}
export const INITIAL_USERS_VALUES = [
  {
    codUser: 'N11111',
    name: 'Ana Abad Aguilar',
    type: 'Interno',
    area: {
      country: 'Global',
      acronym: 'GL'
    },
    lastAccess: '01/01/2021',
    vip: 'Si',
    permissions: ['I', 'R'],
    notifications: 'No',
    mail:'',
    ariesVisibilityData:[],
    comments:[],
    notificationsDetail:{
      push: false,
      mail: false,
      rem: [],
      cco: []
    },
    permissionsDetail: [
      {
        name: "reports",
        value: true
      },
      {
        name: "maintenance",
        value: false
      },
      {
        name: "recommendations",
        value: true
      },
      {
        name: "administration",
        value: false
      },
      {
        name: "planning",
        value: false
      },
      {
        name: "vip",
        value: true
      },
      {
        name: "mailing",
        value: true
      }
    ]
  },
  {
    codUser: 'N22222',
    name: 'Beatriz Bravo Bueno',
    type: 'Interno',
    area: {
      country: 'España',
      acronym: 'ESP'
    },
    lastAccess: '02/02/2021',
    vip: 'Si',
    permissions: ['I', 'R','C'],
    notifications: 'PUSH',
    mail:'',
    ariesVisibilityData:[],
    comments:[],
    notificationsDetail:{
      push: true,
      mail: true,
      rem: [],
      cco: []
    },
    permissionsDetail: [
      {
        name: "reports",
        value: true
      },
      {
        name: "maintenance",
        value: false
      },
      {
        name: "recommendations",
        value: true
      },
      {
        name: "administration",
        value: false
      },
      {
        name: "planning",
        value: false
      },
      {
        name: "vip",
        value: true
      },
      {
        name: "mailing",
        value: true
      }
    ]
  },
  {
    codUser: 'N33333',
    name: 'Carlos Costa Criado',
    type: 'Interno',
    area: {
      country: 'CORPORACION',
      acronym: 'COR'
    },
    lastAccess: '03/03/2021',
    vip: 'No',
    permissions: ['I', 'R'],
    notifications: 'Correo',
    mail:'damian.diaz@gruposatander.com',
    ariesVisibilityData:[ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial,ariesVisibility.administracion, ariesVisibility.bancaComercial],
    comments:["Nuevo country head de Chile desde marzo '21.",
      "Activado 30/05/2021 por N12345",
      "Desacivado 25/04/2021 por N12345",
      "Director de Riesgos en Chile",
      "Activado 23/01/2021 por N12345"],
    notificationsDetail:{
      push: true,
      mail: true,
      rem: ["Auditoria Interna - Audify <audify@gruposantander.com>"],
      cco: ["audify@gruposantander.com","dboj@gruposantander.com","gregfernandez@gruposantander.com"]
    },
    permissionsDetail: [
      {
        name: "reports",
        value: true
      },
      {
        name: "maintenance",
        value: false
      },
      {
        name: "recommendations",
        value: true
      },
      {
        name: "administration",
        value: false
      },
      {
        name: "planning",
        value: false
      },
      {
        name: "vip",
        value: true
      },
      {
        name: "mailing",
        value: true
      }
    ]
  },
  {
    codUser: 'eva.espinosa@empresa.com',
    name: 'Eva Espinosa Espejo',
    type: 'Aries',
    area: {
        country: 'BRASIL',
        acronym: 'BR'
    },
    lastAccess: '',
    vip: '',
    permissions: [],
    notifications: '',
    mail:'eva.espinosa@empresa.com',
    ariesVisibilityData:['BR-Asset Maanagement'],
    comments:[],
    notificationsDetail:{
      push: false,
      mail: false,
      rem: [],
      cco: []
    },
    permissionsDetail: [
      {
        name: "reports",
        value: true
      },
      {
        name: "maintenance",
        value: false
      },
      {
        name: "recommendations",
        value: true
      },
      {
        name: "administration",
        value: false
      },
      {
        name: "planning",
        value: false
      },
      {
        name: "vip",
        value: true
      },
      {
        name: "mailing",
        value: true
      }
    ]
  },
];

export const ACCESS_TYPES = [
  {
    cod: '1',
    name: 'Interno',
  },
  {
    cod: '2',
    name: 'Externo',
  },
  {
    cod: '3',
    name: 'Aries',
  },
];

export const PERMISSIONS = [
  {
    cod: '1',
    name: 'Informes',
  },
  {
    cod: '2',
    name: 'Recomendaciones',
  },
  {
    cod: '3',
    name: 'Planificación',
  },
  {
    cod: '4',
    name: 'Mantenimiento',
  },
  {
    cod: '5',
    name: 'Administrador',
  },
  {
    cod: '6',
    name: 'VIP',
  },
];

export const DEPARTMENTS = [];
export const CODE = 'EXM@K41J';
