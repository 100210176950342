import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { AuthService } from 'src/app/core/services/app/auth/auth.service';

export type BottomMenuOption = 'ratings' | 'emisiones' | 'recomendaciones' | 'planificacion';

@Component({
  selector: 'app-bottom-menu-mobile',
  templateUrl: './bottom-menu-mobile.component.html',
  styleUrls: ['./bottom-menu-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BottomMenuMobileComponent implements OnInit {
  @Input() option?: BottomMenuOption;

  @Output() optionChange = new EventEmitter<BottomMenuOption>();

  @HostBinding('class.iphone-x') get isAnIphoneX() {
    return this.platform.IOS && (screen.width / (screen.height ?? 1)).toFixed(3) === '0.462';
  }

  userConfig$ = this.authService.userConfig$;

  activeIcon = '';

  constructor(
    private authService: AuthService,
    private iconRegistry: MatIconRegistry,
    private platform: Platform,
    private sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      'trabajos-ratings',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/mobile/b-icons-a-lineal-01-banking-ban-028-stock-exchange.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'emisiones',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/mobile/b-icons-a-lineal-02-chanels-chan-013-communication-message.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'recomendaciones',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/mobile/b-icons-a-lineal-04-functional-func-121-checklist.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'planificacion',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/mobile/b-icons-a-lineal-03-document-doc-022-calendar.svg',
      ),
    );
  }

  ngOnInit() {
    this.activeIcon = window.history.state.activeIcon ?? '';
    const navigateToChart: BottomMenuOption = window.history.state.navigateToChart;
    if (navigateToChart) {
      this.optionChange.emit(navigateToChart);
    }
  }

  onOptionChange(option: BottomMenuOption) {
    // this.activeIcon = window.history.state.activeIcon ?? '';
    this.option = option;
    this.optionChange.emit(option);
  }
}
