import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Environment } from '../../environments/environment';

import { ApiService } from './services/app/api/api.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AuthService } from './services/app/auth/auth.service';
import { ENVIRONMENT, LOGOUT } from './constants/global-tokens';
import { NotificationService } from './services/app/notification/notification.service';
import { RequestService } from './services/app/request/request.service';
import { TermsModalComponent } from './components/terms-modal/terms-modal.component';

function logoutFactory(authService: AuthService) {
  return authService.logout$;
}

@NgModule({
  declarations: [TermsModalComponent],
  imports: [
    HttpClientModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    TranslateModule,
  ],
})
export class CoreModule {
  static forRoot(environment: Environment): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: LOCALE_ID, useValue: 'es-ES' },
        { provide: ENVIRONMENT, useValue: environment },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true,
        },
        { provide: LOGOUT, useFactory: logoutFactory, deps: [AuthService] },
        ApiService,
        NotificationService,
        RequestService,
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule, translate: TranslateService) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
