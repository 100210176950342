<button
  *ngIf="(userConfig$ | async)?.swInformes === 'S'"
  class="menu-mobile-btn"
  mat-button
  [class.menu-mobile-btn--active]="
    (activeIcon && activeIcon === 'ratings') || (option === 'ratings' && activeIcon === '')
  "
  (click)="onOptionChange('ratings')"
>
  <mat-icon svgIcon="trabajos-ratings" class="menu-mobile-btn__icon"></mat-icon>
  <span>{{ 'BOTTOM_MENU.RATINGS' | translate }}</span>
</button>

<button
  *ngIf="(userConfig$ | async)?.swInformes === 'S'"
  class="menu-mobile-btn"
  mat-button
  [class.menu-mobile-btn--active]="
    (activeIcon && activeIcon === 'emisiones') || (option === 'emisiones' && activeIcon === '')
  "
  (click)="onOptionChange('emisiones')"
>
  <mat-icon svgIcon="emisiones" class="menu-mobile-btn__icon"></mat-icon>
  <span>{{ 'BOTTOM_MENU.EMISSIONS' | translate }}</span>
</button>

<button
  *ngIf="(userConfig$ | async)?.swRecomendaciones === 'S'"
  class="menu-mobile-btn"
  mat-button
  [class.menu-mobile-btn--active]="
    (activeIcon && activeIcon === 'recomendaciones') ||
    (option === 'recomendaciones' && activeIcon === '')
  "
  (click)="onOptionChange('recomendaciones')"
>
  <mat-icon svgIcon="recomendaciones" class="menu-mobile-btn__icon"></mat-icon>
  <span>{{ 'BOTTOM_MENU.RECOMMENDATIONS' | translate }}</span>
</button>

<button
  *ngIf="(userConfig$ | async)?.swPlanificacion === 'S'"
  class="menu-mobile-btn"
  mat-button
  [class.menu-mobile-btn--active]="
    (activeIcon && activeIcon === 'planificacion') ||
    (option === 'planificacion' && activeIcon === '')
  "
  (click)="onOptionChange('planificacion')"
>
  <mat-icon svgIcon="planificacion" class="menu-mobile-btn__icon"></mat-icon>
  <span>{{ 'BOTTOM_MENU.PLANNING' | translate }}</span>
</button>
