import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(content: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(content);
    this.fitColumns(worksheet);
    //this.fitRowOne(worksheet);
    //this.wrapAndCenterCell(content);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);


  }

  // private fitRowOne(worksheet: XLSX.WorkSheet) {

  //   worksheet["A1"].s = {
  //     font: {
  //       name: 'Song Style',
  //       sz: 24,
  //       bold: true,
  //       underline: true,
  //       color: {
  //         rgb: "FFFFAA00"
  //       }
  //     },
  //     alignment: {
  //       horizontal: "center",
  //       vertical: "center",
  //       wrap_text: true
  //     },
  //     fill: {
  //       bgColor: {
  //         rgb: 'ffff00'
  //       }
  //     }
  //   };


  // }

  private fitColumns(worksheet: XLSX.WorkSheet) {
    var wscols = [
      { width: 9 },  // first column
      { width: 50 }, // second column
      { width: 7 }, //...
      { width: 50 },
      { width: 28 },
      { width: 4 },
      { width: 10 },
      { width: 15 }
    ];
    worksheet['!cols'] = wscols;


  }

  // private wrapAndCenterCell(cell: any) {
  //   const result = [];
  //   let row: any;
  //   let rowNum: any;
  //   let colNum: any;
  //   const range = XLSX.utils.decode_range(cell['!ref']);
  //   for (rowNum = range.s.r; rowNum <= 0; rowNum++) {
  //     row = [];
  //     for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
  //       const nextCell = cell[
  //         XLSX.utils.encode_cell({
  //           r: rowNum,
  //           c: colNum
  //         })
  //       ];
  //       if (rowNum == 0) {
  //         row.push(nextCell.s = {
  //           hpt: 21,
  //           hpx: 16,
  //           font: {
  //             size: 16,
  //             color: {
  //               rgb: "#FFFFFF"
  //             },
  //             bold: true
  //           },
  //           alignment: {
  //             horizontal: "center",
  //             vertical: "center",
  //             wrap_text: true
  //           },
  //           fill: {
  //             patternType: "none", // none / solid
  //             fgColor: { rgb: "#FF0000" },
  //             bgColor: { rgb: "#FFFFFF" }
  //           },
  //         })
  //       }
  //     }
  //     result.push(row);
  //   }
  // }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
