import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { cloneDeep, isNil } from 'lodash-es';
import { NuevoInforme } from 'src/app/core/models/entities/nuevo-informe';
import { NotificationService } from 'src/app/core/services/app/notification/notification.service';
import { getDefaultNavigationExtras } from 'src/app/core/utils/router';

import { DialogService } from '../../../dialog/services/dialog.service';
import { Division } from '../../../core/models/entities/division';
import { Geography } from '../../../core/models/entities/geography';

import { FilterModalMobileComponent } from './filter-modal-mobile/filter-modal-mobile.component';

type GeographyModel = Omit<Geography, 'subGeografias'> & {
  allComplete?: boolean;
  collapsed?: boolean;
  checked: boolean;
  subGeografias: GeographyModel[];
};

const mapGeographyArray = (
  geographies: Readonly<Geography[] | null | undefined>,
): GeographyModel[] =>
  geographies?.map(g => ({
    ...g,
    allComplete: false,
    checked: false,
    collapsed: true,
    subGeografias: mapGeographyArray(g.subGeografias),
  })) ?? [];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnChanges {
  @Input() geographies?: Readonly<Geography[] | null>;
  @Input() userDivisions?: Readonly<Division[] | null>;
  @Input() selectedGeographiesCodes?: Readonly<string>;
  @Input() selectedUserDivision?: Readonly<number>;
  @Input() sidenavOpened: Readonly<boolean> = false;
  @Input() handset: Readonly<boolean> = false;
  @Output() toggleSidenav = new EventEmitter<void>();

  @ViewChild('searchInput') public searchInput: ElementRef<HTMLElement> | undefined;

  isOpenGeographyMenu = false;
  isOpenAreaMenu = false;
  isOpenedNotifications = localStorage.getItem('audify:isOpenedNotifications') === 'false';
  geographiesModel: GeographyModel[] = [];
  selectedGeographiesNames?: string;
  selectedUserDivisionName?: string;

  notifications!: NuevoInforme[];
  show = true;

  private dialogRef: MatDialogRef<FilterModalMobileComponent> | undefined;

  constructor(
    private breakpointObserver: BreakpointObserver,
    iconRegistry: MatIconRegistry,
    private dialogService: DialogService,
    private ref: ChangeDetectorRef,
    private router: Router,
    sanitizer: DomSanitizer,
    private notificationService: NotificationService,
  ) {
    iconRegistry.addSvgIcon(
      'func-071-search',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/func-071-search.svg'),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    //this.getNotifications();
    if (this.notifications?.length === 0 && this.show) {
      this.show = false;
    }
    if (changes.userDivisions || changes.selectedUserDivision) {
      this.selectedUserDivisionName = this.userDivisions?.find(
        d => d.cdDivision === this.selectedUserDivision,
      )?.nbDivision;
    }

    if (changes.geographies) {
      this.geographiesModel = mapGeographyArray(this.geographies);
    }

    if (changes.geographies || changes.selectedGeographiesCodes) {
      this.updateSelectedGeographies();
    }
  }

  private updateSelectedGeographies() {
    const codes = this.selectedGeographiesCodes?.split(',') || [];
    this.geographiesModel.forEach(g => {
      g.allComplete = codes.includes(g.cdGeografia);
      g.checked = g.allComplete;
      g.subGeografias?.forEach(sub => {
        sub.checked = g.checked || codes.includes(sub.cdGeografia);
      });

      this.updateAllComplete(g);
    });

    this.updateButton();
  }

  updateAllComplete(geography: GeographyModel) {
    if (geography?.subGeografias.length > 0) {
      geography.allComplete = geography?.subGeografias.every(t => t.checked) ?? false;
      geography.checked = geography.allComplete;
    }

    this.ref.markForCheck();
  }

  someComplete(geography: GeographyModel): boolean {
    if (geography.subGeografias == null) {
      return false;
    }

    return geography.subGeografias.filter(t => t.checked).length > 0 && !geography.allComplete;
  }

  setAll(geography: GeographyModel, checked: boolean) {
    geography.allComplete = checked;
    geography.checked = checked;
    if (!geography.subGeografias) {
      return;
    }

    geography.subGeografias.forEach(t => (t.checked = checked));
    this.ref.markForCheck();
  }

  cleanAllGeographies() {
    this.geographiesModel = mapGeographyArray(this.geographies);
    this.router.navigate([], {
      queryParams: { geographies: undefined },
      queryParamsHandling: 'merge',
    });
  }

  cleanAllAreas() {
    this.router.navigate([], {
      queryParams: { area: '' },
      queryParamsHandling: 'merge',
    });
  }

  updateButton() {
    let selected = this.geographiesModel.filter(g => g.checked);
    if (selected.length > 1) {
      this.selectedGeographiesNames = 'Varias';
      return;
    }

    if (selected.length === 1) {
      this.selectedGeographiesNames = selected[0]?.nbGeografia;
      return;
    }

    selected = selected.concat(
      ...this.geographiesModel
        .filter(g => !g.checked)
        .map(g => g.subGeografias.filter(inner => inner.checked)),
    );

    if (selected.length > 1) {
      this.selectedGeographiesNames = 'Varias';
      return;
    }

    if (selected.length === 1) {
      this.selectedGeographiesNames = selected[0]?.nbGeografia;
      return;
    }

    this.selectedGeographiesNames = undefined;
  }

  onChangeArea(area: Division) {
    this.router.navigate([], {
      queryParams: { area: area.cdDivision },
      queryParamsHandling: 'merge',
    });
  }

  onChangeGeographies() {
    let newSelectedGeographiesCodes = this.geographiesModel.filter(g => g.checked);
    newSelectedGeographiesCodes = newSelectedGeographiesCodes
      .concat(
        ...this.geographiesModel
          .filter(g => !g.checked)
          .map(g => g.subGeografias.filter(inner => inner.checked)),
      )
      .sort((g1, g2) => g1.cdGeografia.localeCompare(g2.cdGeografia));
    this.router.navigate([], {
      queryParams: { geographies: newSelectedGeographiesCodes.map(g => g.cdGeografia).join(',') },
      queryParamsHandling: 'merge',
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this.dialogRef) {
      this.dialogRef = undefined;
      history.go(1);
    }
  }

  openFilterModal() {
    this.dialogRef = this.dialogService.showCustomDialog(FilterModalMobileComponent, {
      config: {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'filter-modal',
      },
      data: {
        geographies: cloneDeep(this.geographiesModel),
        userDivisions: cloneDeep(this.userDivisions),
        selectedUserDivision: this.selectedUserDivision,
        selectedGeographiesCodes: this.selectedGeographiesCodes,
        selectedGeographiesNames: this.selectedGeographiesNames,
      },
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (!isNil(result)) {
        this.dialogRef = undefined;
      }

      if (!result) {
        return;
      }

      this.router.navigate([], {
        queryParams: {
          area: result.selectedUserDivision,
          geographies: result.selectedGeographiesCodes,
        },
        queryParamsHandling: 'merge',
      });

      this.ref.markForCheck();
    });
  }

  setIsOpenGeographyMenu(value: boolean) {
    this.isOpenGeographyMenu = value;
    if (value) {
      this.updateSelectedGeographies();
    }
  }

  onSearchClick(page: string, q: string) {
    this.router.navigate(
      [page],
      getDefaultNavigationExtras({ queryParams: { q: q || undefined } }),
    );
    this.searchInput?.nativeElement.blur();
  }

  getNotifications() {
    this.notificationService.getNotifications().subscribe(notifications => {
      this.notifications = notifications;
      if (this.notifications?.length === 0 && this.show) {
        this.show = false;
      }

      this.ref.markForCheck();
    });
  }

  toggleNotification() {
    this.show = !this.show;
  }
  showNotification() {
    this.show = true;
  }

  updateNotificationBadge() {
    this.isOpenedNotifications = localStorage.getItem('audify:isOpenedNotifications') === 'true';
    this.ref.markForCheck();
  }
}
