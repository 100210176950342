import { ComponentType } from '@angular/cdk/portal';
import { Inject, Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogResult, DialogType, MessageDialogComponent } from '../components/dialogs';
import { LOGOUT } from '../../core/constants/global-tokens';

@Injectable()
export class DialogService implements OnDestroy {
  private dialogRefs: MatDialogRef<any>[] = [];
  private destroy$ = new Subject();

  constructor(private dialog: MatDialog, @Inject(LOGOUT) private logout: Observable<void>) {
    this.logout
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dialogRefs.forEach(ref => ref.close()));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  showCustomDialog<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    options?: { disableClose?: boolean; config?: Record<string, any>; data?: Record<string, any> },
  ): MatDialogRef<T> {
    const ref = this.dialog.open(componentOrTemplateRef, {
      data: options?.data,
      disableClose: options?.disableClose ?? false,
      ...(options?.config ?? {}),
    });

    this.dialogRefs.push(ref);
    return ref;
  }

  showConfirmDialog(
    title: string,
    message: string,
    icon?: string,
    options?: { htmlMessage: boolean },
    width?: string,
  ): Observable<DialogResult> {
    const dialog = this.dialog.open(MessageDialogComponent, {
      data: { title, message, icon, options, type: DialogType.Confirm },
      width,
    });

    this.dialogRefs.push(dialog);
    return dialog.componentInstance.dialogResult$;
  }

  showMessageDialog(title: string, message: string): Observable<DialogResult> {
    const dialog = this.dialog.open(MessageDialogComponent, {
      data: { title, message, type: DialogType.Message },
      disableClose: true,
    });

    this.dialogRefs.push(dialog);
    return dialog.componentInstance.dialogResult$;
  }
}
