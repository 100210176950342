import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SecurityContext,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { InformeEstatico } from 'src/app/core/models/entities/InformeEstatico';
import { NuevoInforme } from 'src/app/core/models/entities/nuevo-informe';
import { NotificationService } from 'src/app/core/services/app/notification/notification.service';
import { LanguageService } from 'src/app/core/services/entities/language.service';
import { isMobile } from 'src/app/core/utils/files';
import { getDefaultNavigationExtras } from 'src/app/core/utils/router';

import { DialogService } from '../../../dialog/services/dialog.service';
import { TermsModalComponent } from '../../../core/components/terms-modal/terms-modal.component';
import Utils from '../../../dashboard/utils';
import { ValueTransformer } from '@angular/compiler/src/util';
import { AuthService } from 'src/app/core/services/app/auth/auth.service';
import { Observable, of } from 'rxjs';
import { ConfigUsuario } from 'src/app/core/models/entities/config-usuario';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, AfterViewChecked {
  @Input() staticReport?: InformeEstatico[] | null;

  isOpenedNotifications = localStorage.getItem('audify:isOpenedNotifications') === 'false';
  lang = this.translate.currentLang;
  show = false;
  isAdmin = false;
  notifications!: NuevoInforme[];
  utils: Utils = new Utils();

  @Output() notifyOpenedNotifications = new EventEmitter();
  @Output() downloadDocument = new EventEmitter();
  @Output() downloadReport = new EventEmitter();
  @Output() toggleSidenav = new EventEmitter();

  @ViewChild('buttonen') buttonen: ElementRef | undefined;
  @ViewChild('buttones') buttones: ElementRef | undefined;
  @ViewChild('buttonpt') buttonpt: ElementRef | undefined;

  constructor(
    private dialogService: DialogService,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private languageService: LanguageService,
    private router: Router,
    private ref: ChangeDetectorRef,
    public translate: TranslateService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private readonly dompurifySanitizer: NgDompurifySanitizer
  ) { }

  ngOnInit() {
    this.getNotifications();
    this.verificacion();
  }

  ngAfterViewChecked(): void {
    this.lang = this.translate.currentLang;
    if (this.lang === 'en_US') {
      this.resolveEnUsButton();
    } else if (this.lang === 'es_ES') {
      this.resolveEsEsButton();
    } else if (this.lang === 'pt_PRT') {
      this.resolvePtPrtButton();
    }
  }
  resolveEnUsButton() {
    if (this.buttonen) {
      this.buttonen.nativeElement.style.backgroundColor = '#e0e0e0';
    }
    if (this.buttones) {
      this.buttones.nativeElement.style.backgroundColor = 'white';
    }
    if (this.buttonpt) {
      this.buttonpt.nativeElement.style.backgroundColor = 'white';
    }
  }
  resolveEsEsButton() {
    if (this.buttonen) {
      this.buttonen.nativeElement.style.backgroundColor = 'white';
    }
    if (this.buttones) {
      this.buttones.nativeElement.style.backgroundColor = '#e0e0e0';
    }
    if (this.buttonpt) {
      this.buttonpt.nativeElement.style.backgroundColor = 'white';
    }
  }
  verificacion(): void {
    this.authService.isUserAdmin().subscribe(data => {
      if (data.swAdministrador == 'S') {
        this.isAdmin = true;
      }
    });
  }

  resolvePtPrtButton() {
    if (this.buttonen) {
      this.buttonen.nativeElement.style.backgroundColor = 'white';
    }
    if (this.buttones) {
      this.buttones.nativeElement.style.backgroundColor = 'white';
    }
    if (this.buttonpt) {
      this.buttonpt.nativeElement.style.backgroundColor = '#e0e0e0';
    }
  }

  onLangPressed(value: string) {
    this.lang = '';
    this.lang = value;
    this.translate.use(value);

    if (this.buttonen) {
      this.buttonen.nativeElement.style.backgroundColor = '#000';
    }
    let backLang = 'ESP';
    if (value.includes('en')) {
      backLang = 'ENG';
    } else if (value.includes('pt')) {
      backLang = 'POR';
    }
    const callback = () =>
      this.router.navigate(['.'], getDefaultNavigationExtras({ queryParams: { lang: this.lang } }));

    this.languageService.getUserGeographies(backLang).subscribe(callback, callback);
    (window as any).webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({ action: 'setLang', data: backLang }));
  }

  /*onUserManagementPressed() {
    this.router.navigate(['/user-management']);
  }*/

  openTermsModal() {
    const dialogRef = this.dialogService.showCustomDialog(TermsModalComponent, {
      config: { maxHeight: '80vh' },
      disableClose: true,
    });

    dialogRef.afterOpened().subscribe(() => {
      const overlayEl = document.querySelector<HTMLDivElement>('.cdk-overlay-container');
      overlayEl?.classList.add('no-scroll-overlay');
    });

    dialogRef.afterClosed().subscribe(() => {
      this.document.cookie = 'terms=true';
    });
  }

  toggleNotification(isCloseButton: boolean) {
    if (this.notifications.length > 0) {
      if (isCloseButton) {
        this.closeNotificationPanel();
      } else {
        this.show = !this.show;
      }
      if (this.show) {
        this.isOpenedNotifications = true;
        localStorage.setItem('audify:isOpenedNotifications', 'true');
        this.notifyOpenedNotifications.emit();
      }
    }
  }

  closeNotificationPanel() {
    this.show = false;
    this.ref.markForCheck();
  }

  getNotifications() {
    this.notificationService.getNotifications().subscribe(notifications => {
      this.notifications = notifications;
      this.ref.markForCheck();
    });
  }

  onReportClick(report: InformeEstatico) {
    this.downloadDocument.emit(report.rgInforme);
  }

  onNotificationClick(notification: NuevoInforme) {
    this.downloadReport.emit(notification.rgTrabajo);
  }

  navigateUserManagement() {
    this.toggleSidenav.emit();
  }

  logOut() {
    if (isMobile()) {
      localStorage.clear();
      (window as any).webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({ action: 'logout' }));
    } else {
      localStorage.clear();
      this.authService.accessConfig(false,true);
    }
  }
}
