import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/app/auth/auth.service';

import { CodigoActivacionDTO, DataModalMessage } from 'src/app/shared/models/audify';
import { UserManagementService } from '../services/user-management.service';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalMessageComponent implements OnInit {
  @Input() data: DataModalMessage;
  @Input() iconClose: boolean;
  @Output() closeModalevent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<ModalMessageComponent>,
    private userManageService: UserManagementService,
    private authService: AuthService,
    private ref: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public userData: any,
  ) {
    this.data = userData;
    this.iconClose = false;
  }

  ngOnInit(): void {
    // TODO
  }

  closeModal(): void {
    this.closeModalevent.emit(true);
  }

  regenerateKey(): void {
    this.authService.getAccessToken().subscribe( token => {
      if(token) {
      this.userManageService.getCambioClave(token).subscribe( (res: CodigoActivacionDTO) => {
        this.data.code = res.installCode;
        this.ref.markForCheck();
      })
    }
    })
  }

  sendNewKey(newKey: string): void {
    
  }

}
