import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, map, mapTo, scan, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/services/app/loading/loading.service';

export function onLangChange(
  translate: TranslateService,
  destroy$: Subject<unknown>,
  loading?: LoadingService,
) {
  let obs$ = translate.onLangChange.pipe(map(value => value.lang));

  if (loading) {
    obs$ = obs$.pipe(
      switchMap(lang =>
        loading.isLoading$.pipe(
          scan((obj, curr) => ({ prev: obj.curr, curr }), { prev: false, curr: false }),
          filter(obj => obj.prev),
          mapTo(lang),
          take(1),
        ),
      ),
    );
  }

  return obs$.pipe(startWith(translate.currentLang), takeUntil(destroy$));
}
