import { Injectable } from '@angular/core';

import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private pdfViewer?: PdfJsViewerComponent;

  openFileInExternalWindow(file: Blob | Uint8Array, filename: string) {
    if (!this.pdfViewer) {
      return;
    }

    this.pdfViewer.viewerTab = undefined;
    this.pdfViewer.pdfSrc = file; // pdfSrc can be Blob or Uint8Array
    this.pdfViewer.downloadFileName = filename;
    // Ask pdf viewer to load/refresh pdf
    setTimeout(() => this.pdfViewer?.refresh(), 2000);
  }

  setPdfEditor(pdfViewer: PdfJsViewerComponent) {
    this.pdfViewer = pdfViewer;
  }
}
